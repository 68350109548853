<app-navbar></app-navbar>

<div class="confirmation-block">
  <h3 style="background-color: #FFF;">Your Order - {{ order.is_Delivery ? 'DELIVERY' : 'COLLECTION' }}</h3>

  <div class="order-block">
    <div *ngIf="order.order_State == 0">
      <h5>PENDING</h5>
    </div>
    <div *ngIf="order.order_State == 1">
      <h5>ACCEPTED</h5>
    </div>
    <div *ngIf="order.order_State == 2 && order.is_Delivery">
      <h5>OUT FOR DELIVERY</h5>
    </div>
    <div *ngIf="order.order_State == 2 && !order.is_Delivery">
      <h5>READY FOR COLLECTION</h5>
    </div>
    <div *ngIf="order.order_State == 3">
      <h5>COMPLETE</h5>
    </div>
    <div *ngIf="order.order_State == 4">
      <h5>REJECTED</h5>
    </div>

    <div class="fa-2x spinner" *ngIf="order.order_State < 2">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>

    <div class="thumbs-up" *ngIf="order.order_State == 2 || order.order_State == 3">
      <i class="far fa-thumbs-up"></i>
    </div>

    <div class="totals">
      <span>{{ order.cost | currency }}</span><br/>
      {{ (order.stripe_Charge_Id != null && order.stripe_Charge_Id != "") || (order.stripe_Payment_Intent_Id != null && order.stripe_Payment_Intent_Id != "") ? "CARD" : "CASH" }}
    </div>

    <div class="order-eta" *ngIf="order.order_State < 3 && order.etA_Minutes != null">
      ETA: <span>{{ eta }} mins</span>
    </div>


    <ul style="padding: 10px;">
      <li *ngFor="let product of order.items" class="product-outer-block">
        <div class="product-block">
          <div class="product-info">
            <div class="product-name">{{ product.name }}</div>
            <!-- Options -->
            <div class="product-options" *ngIf="product.options">
              <div *ngFor="let option of product.options">
                <div class="option">{{ option.name }}</div>
                <div class="product-extras product-option-extras" >
                  <div *ngFor="let extraCategory of option.extras">
                    <div class="extra" *ngFor="let extra of extraCategory.extras">+ {{ extra.name }}</div>
                    <!-- <div class="extra" *ngFor="let extra of extraCategory.extras">+ {{ extra.name }} ({{ extra.cost | currency }})</div> -->
                  </div>
                </div>
              </div>
            </div>

            <p class="product-extra-title" *ngIf="product.extras && product.extras.length > 0">EXTRAS</p>
            <div class="product-extras" >
              <div *ngFor="let extraCategory of product.extras">
                <!-- <div class="extra" *ngFor="let extra of extraCategory.extras">+ {{ extra.name }} ({{ extra.cost | currency }})</div> -->
                <div class="extra" *ngFor="let extra of extraCategory.extras">+ {{ extra.name }}</div>
              </div>
            </div>
          </div>

          <div class="product-cost">
            <div class="cost">
              {{ product.cost | currency }}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div style="font-weight: bold; text-align: center; background-color: #FFF; padding: 10px; padding-top: 50px;" *ngIf="app != null">
  <p>Thank you for placing your order with us.</p>
  <p>{{ app.name }}</p>
  <p *ngIf="app.id != 195">{{ app.address_Line_1 }}, {{ app.postcode }}</p>
  <p>{{ app.phone }}</p>
</div>
