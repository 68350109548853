<div class="hero" [ngClass]="{'offset-img': app.id == 181}" *ngIf="app">
  <app-navbar></app-navbar>

  <div class="content">
    <div class="logo">
      <img src="../../../assets/logo.png" />
    </div>

    <!-- <h1>THE BIGGEST AND BEST CHICKEN CHAIN</h1> -->
    <a class="btn-menu" routerLink="/menu">VIEW OUR MENU</a>
  </div>

  <div class="order-outer-block">
    <div class="order-discount-block">
      <div class="discount-content" *ngIf="app.app_Discount > 0">
        GET <span>{{ app.app_Discount }}%</span> OFF
      </div>

      <div class="order-content">
        <h2>ORDER ONLINE</h2>
        <h4>How to order?</h4>
        <small>Follow the steps</small>

        <div class="steps" *ngIf="app.id == 181">
          <div class="step">
            <i class="far fa-clock"></i>
            <div class="step-no">
              <span class="numberCircle"><span>1</span></span>
            </div>
            <p>Place your order</p>
          </div>
          <div class="step">
            <i class="fas fa-bicycle"></i>
            <div class="step-no">
              <span class="numberCircle"><span>2</span></span>
            </div>
            <p>Delivery or collection</p>
          </div>
          <div class="step">
            <i class="fas fa-thumbs-up"></i>
            <div class="step-no">
              <span class="numberCircle"><span>3</span></span>
            </div>
            <p>Sit back and relax.</p>
          </div>
        </div>

        <p *ngIf="app.id == 181" style="font-weight: bold;">
          We cover all events, parties, and home deliveries. Find out more, call us on {{ app.phone }}
        </p>

        <div class="steps" *ngIf="app.id != 181">
          <div class="step">
            <i class="fas fa-location-arrow"></i>
            <div class="step-no">
              <span class="numberCircle"><span>1</span></span>
            </div>
            <p>Choose your location</p>
          </div>
          <div class="step">
            <i *ngIf="app.id != 195" class="fas fa-pizza-slice"></i>
            <i *ngIf="app.id == 195" class="fas fa-shopping-basket"></i>
            <div class="step-no">
              <span class="numberCircle"><span>2</span></span>
            </div>
            <p>Place your order</p>
          </div>
          <div class="step">
            <i class="fas fa-bicycle"></i>
            <div class="step-no">
              <span class="numberCircle"><span>3</span></span>
            </div>
            <p *ngIf="app.id != 195">Delivery or collection</p>
            <p *ngIf="app.id == 195">Get your delivery</p>
          </div>
          <div class="step">
            <i class="fas fa-thumbs-up"></i>
            <div class="step-no">
              <span class="numberCircle"><span>4</span></span>
            </div>
            <p *ngIf="app.id != 195">Your food is ready</p>
            <p *ngIf="app.id == 195">Your order is ready</p>
          </div>
        </div>
      </div>
    </div>

    <div class="app-content">
      <div class="app-info-container">
        <div class="info">
          <h3 *ngIf="app.id != 181">Get Your Favourite Food Fast With Our App</h3>
          <h3 *ngIf="app.id == 181">Get 10% of your order with our app</h3>
        </div>
        <img *ngIf="app.id != 181" src="../../../assets/online-order.svg" />
        <img *ngIf="app.id == 181" src="../../../assets/online-order.png" />
        <div class="app-stores">
          <a class="store" [href]="android_store" target="_blank">
            <i class="fab fa-android"></i>
            <p>GOOGLE PLAY</p>
          </a>
          <a class="store" [href]="apple_store" target="_blank">
            <i class="fab fa-apple"></i>
            <p>APP STORE</p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="about-us-content">
    <h1>Our Message To You</h1>
    <p>
      Rumaan Manchester offers a hassle free and bespoke delivery service for its customers. 
      We provide shisha, snacks, mocktails, soft drinks and fresh hot donuts! 
      We also collect the Shisha the following day at a time that suits you best.
    </p>

    <p>
      Our vision is to provide you with the best experience in an environment of your choice. 
      That could be a party, wedding or just a lazy day in! Just follow the options with your 
      requirements and find out if we deliver in your area. 
    </p>
    
    <p>
      You could always drop us a message or call us if your request is more specific, for 
      example if you wanted to hire for a large event/party or you require an employee to 
      help you with coal changes etc. No request is too small or too big. We shall endeavour to please!
    </p>
  </div> -->

  <div class="contact-content">
    <div class="phone">
      <div>Call us: {{ app.phone }} <br/> <a routerLink="/privacy" style="margin-top: 10px; display: block; font-size: 12px;">OUR PRIVACY POLICY</a></div>
    </div>
  </div>
</div>