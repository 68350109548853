import { Component, OnInit } from '@angular/core';
import { BasketService } from 'src/app/services/basket.service';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {

  isSummaryOpen: boolean = false;
  products = [];
  qty = 0;
  total = null;
  subTotal = null;
  allowPreOrder: boolean = false;
  etaTime: string;

  constructor(private basketService: BasketService) {
    basketService.products.subscribe((prods) => {
      if (prods == null)
        return;
    
      this.qty = 0;
      for (let product of prods) {
        this.qty += product.qty;
      }
      
      this.products = prods;
    });

    basketService.costs.subscribe((costs) => {
      if (costs == null)
        return;

      this.total = costs.total;
      this.subTotal = costs.subTotal;
    })

    basketService.allowPreOrder.subscribe((allowPreOrder) => {
      if (allowPreOrder != null) {
        this.allowPreOrder = allowPreOrder.allow;
        this.etaTime = allowPreOrder.etaTime;
      }
    });
  }

  ngOnInit(): void {
  }

  clearPreOrder() {
    this.basketService.updatePreOrder(false, null, false);
  }
}
