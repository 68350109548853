<div class="logo">
  <img src="../../../assets/logo.png"/>
</div>

<div class="latest-order-block" *ngIf="order != null">
  <div class="latest-order">
    <div *ngIf="order.order_State == 0">
      <h5>PENDING</h5>
    </div>
    <div *ngIf="order.order_State == 1">
      <h5>ACCEPTED</h5>
    </div>
    <div *ngIf="order.order_State == 2 && order.is_Delivery">
      <h5>OUT FOR DELIVERY</h5>
    </div>
    <div *ngIf="order.order_State == 2 && !order.is_Delivery">
      <h5>READY FOR COLLECTION</h5>
    </div>
    <div *ngIf="order.order_State == 3">
      <h5>COMPLETE</h5>
    </div>
    <div *ngIf="order.order_State == 4">
      <h5>REJECTED</h5>
    </div>

    <div class="fa-2x spinner" *ngIf="order.order_State < 2">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>

    <div class="thumbs-up" *ngIf="order.order_State == 2 || order.order_State == 3">
      <i class="far fa-thumbs-up"></i>
    </div>

    <div class="totals">
      <span>{{ order.cost | currency }}</span><br/>
      {{ order.isCard ? "CARD" : "CASH" }}
    </div>

    <a routerLink="/confirmation" (click)="selectedNav()">View Order</a>
  </div>
</div>

<ul>
  <li><a routerLink="/" (click)="selectedNav()"><i class="fas fa-home"></i>Home</a></li>
  <li><a routerLink="/menu" (click)="selectedNav()"><i class="fas fa-utensils"></i>Menu</a></li>
  <li><a routerLink="/contact" (click)="selectedNav()"><i class="fas fa-mobile-alt"></i>Contact</a></li>
  <li><a routerLink="/terms" (click)="selectedNav()"><i class="fas fa-user-secret"></i>Terms &amp; Conditions</a></li>
  <li><a routerLink="/privacy" (click)="selectedNav()"><i class="fas fa-user-secret"></i>Our Privacy Policy</a></li>
</ul>

<div style="margin-top: 10px;" *ngIf="showCategories">
  <app-categories (selected)="selectedNav(); onSelected($event);"></app-categories>
</div>