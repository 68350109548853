<div class="sizes-block" *ngIf="product.sizes != null && product.sizes.length > 0 && !isMealDeal">
  <h5>Sizes</h5>
  <div class="sizes">
    <div class="size-block" [ngClass]="{'selected': selectedSize.id == size.id}" *ngFor="let size of product.sizes"
      (click)="selectSize(size)">
      <div class="size">
        <div>
          <div class="size-text">{{ size.size }}</div>
          <div class="cost-text">{{ size.surplus | currency }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="sizes" *ngIf="additionalSizes.length > 0 && !isMealDeal">
    <div class="size-block" [ngClass]="{'selected': selectedAdditionalSize.id == size.id}"
      *ngFor="let size of additionalSizes" (click)="selectAdditionalSize(size)">
      <div class="size">
        <div>
          <div class="size-text">{{ size.option }}</div>
          <div class="cost-text">{{ size.surplus | currency }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<div class="extras-block" *ngIf="product.extras != null && product.extras.length > 0">
  <h5>Extras</h5>

  <div *ngFor="let extraCategory of product.extras">
    <h6>{{ extraCategory.name }}<span *ngIf="extraCategory.maximum > 0"> - Maximum of {{ extraCategory.maximum }}</span></h6>
    <p *ngIf="extraCategory.no_Of_Free > 0" class="free-block">Your first {{ extraCategory.no_Of_Free }} cheapest extras will be free!</p>

    <div class="extra-list-block-container" style="margin-top: 10px" *ngIf="extraCategory.maximum == 1">
      <label class="extra-list-block" *ngFor="let extra of extraCategory.extras">
        <div class="extra-name">
          {{ extra.name }} 
          <span *ngIf="appId != 188">{{ extra.cost | price : extra.free : extraSurplus: showExtraSurplus }}</span>
          <span *ngIf="appId == 188">{{ getExtraBaseCost(extra)}}</span>
        </div>
        <input type="radio" (click)="toggleExtra(extra, extraCategory, $event)" [name]="extraCategory.name"/>
      </label>
    </div>

    <div  class="extra-list-block-container" style="margin-top: 10px" *ngIf="extraCategory.maximum != 1">
      <label class="extra-list-block" *ngFor="let extra of extraCategory.extras">
        <img *ngIf="includesImages" [src]="foodUrl + appId + '/extras/' + extra.extra_Id + '.jpg'"/>
        <div class="extra-name-container">
          <div class="extra-name">{{ extra.name }} <span *ngIf="appId != 163">{{ extra.cost | price : extra.free : extraSurplus: showExtraSurplus }}</span></div>
          <div class="extra-description">{{ extra.description }}</div>
        </div>
        <div *ngIf="extra.has_Qty">
          <div class="qty-block">
            <i class="fas fa-minus-circle" [ngClass]="{'qty-minus-selected': extra.qty > 0}"
              (click)="removeQty(extra, extraCategory)"></i>
            <div class="qty">
              {{ extra.qty }}
            </div>
            <i class="fas fa-plus-circle" [ngClass]="{'qty-plus-selected': extra.qty > 0}"
              (click)="addQty(extra, extraCategory)"></i>
          </div>
        </div>
        <div *ngIf="!extra.has_Qty">
          <input type="checkbox" (click)="toggleExtra(extra, extraCategory, $event)" />
        </div>
      </label>
    </div>
  </div>
</div>

<button class="product-details-total" (click)="addToBasket()" *ngIf="!isMealDeal">
  <div>Add To Basket - {{ cost | currency }}</div>
  <i class="far fa-arrow-alt-circle-right"></i>
</button>