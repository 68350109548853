import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import * as moment from 'moment';
import { MatDialogRef } from '@angular/material/dialog';
import { BasketService } from 'src/app/services/basket.service';

@Component({
  selector: 'app-opening-dialog',
  templateUrl: './opening-dialog.component.html',
  styleUrls: ['./opening-dialog.component.scss']
})
export class OpeningDialogComponent implements OnInit {

  allowPreOrders: boolean;
  
  preOrder: boolean = false;
  isDelivery: boolean = null;
  
  etaTime: string = null;

  constructor(private appService: AppService, private basketService: BasketService, public dialogRef: MatDialogRef<OpeningDialogComponent>) { 
  }

  async ngOnInit(): Promise<void> {
    var app = await this.appService.getApp();
    var isClosed = app.opening_Times[moment().day()].isClosed;
    this.allowPreOrders = app.allowPreOrders && !isClosed;


  }

  async setDelivery(isDelivery) {
    this.isDelivery = isDelivery;
  }

  setPreOrder() {
    this.basketService.updatePreOrder(true, this.etaTime, this.isDelivery); 
    this.dialogRef.close(true);
  }

  updateEtaTime(e) {
    this.etaTime = e;
  }
}
