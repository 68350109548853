import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import * as _ from 'lodash';
import { AppService } from 'src/app/services/app.service';
import { BasketService } from 'src/app/services/basket.service';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { trigger, transition, animate, style } from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppConfigService } from 'src/app/services/app-config.service';
import { MatDialog } from '@angular/material/dialog';
import { OpeningDialogComponent } from 'src/app/components/dialogs/opening-dialog/opening-dialog.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(0%)', opacity: 0}),
          animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0%)', opacity: 1}),
          animate('100ms', style({transform: 'translateY(0)', opacity: 0}))
        ])
      ]
    )
  ],
})
export class ProductsComponent implements OnInit {
  isLoading: boolean = true;
  allCategories;
  categories;
  search: string = "";

  selectedProduct: null;

  foodUrl: string = "";
  includesImages: boolean = false;
  appId: number;

  constructor(private appService: AppService, private productService: ProductService, private basketService: BasketService, private router: Router, 
    private deviceService: DeviceDetectorService, private config: AppConfigService, public dialog: MatDialog, ) { }

  ngOnInit(): void {
    this.foodUrl = this.config.data.food_url;
    this.includesImages = this.config.data.includes_images;
    this.appId = this.config.data.app_id;

    this.productService.getAll().then((data) => {
      data = data.filter(a => a.eposOnly == false && a.parent_Category.eposOnly == false);

      // Order by our parent category
      data = _.orderBy(data, ['parent_Category.position'], ['asc']);

      var products = _.chain(data).groupBy(function(product) {
        return product.parent_Category.name
      }).map((value, key) => ({ category: key, products: value, expanded: false})).value();

      if (this.deviceService.isDesktop() || this.deviceService.isTablet()) {
        for (let category of products) {
          category.expanded = true;
        }
      }

      this.allCategories = products;
      this.categories = products;

      this.isLoading = false;
    });
  }

  getChillis(product) {
    if (product.macros == null)
      return;

    var str = "";

    for (var i = 0; i < product.macros.kCal; i++) {
      str += "<i class='fas fa-pepper-hot'></i>";
    }

    return str;
  }

  getVeg(product) {
    if (product.macros == null)
      return;

    var str = "";

    for (var i = 0; i < product.macros.fat; i++) {
      str += "<i class='fas fa-leaf'></i>";
    }
  
    return str;
  }

  getNuts(product) {
    if (product.macros == null)
      return;

    var str = "";

    for (var i = 0; i < product.macros.fibre; i++) {
      str += "<img src='/assets/nuts.png'/>";
    }
  
    return str;
  }

  getGluten(product) {
    if (product.macros == null)
      return;

    var str = "";

    for (var i = 0; i < product.macros.carbs; i++) {
      str += "<img src='/assets/gluten.png'/>";
    }
  
    return str;
  }

  async selectProduct(product) {
    var tempOpen = await this.appService.isTempClosed();
    if (tempOpen.isTempClosed) {
      alert(tempOpen.tempClosedMessage)
      return;
    }

    var isOpen = await this.appService.isOpen();
    if (!isOpen && !this.basketService.allowPreOrder.value.allow) {
      // alert("Sorry we are not currently open. Please see our opening times.");
      const dialogRef = this.dialog.open(OpeningDialogComponent);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.selectProduct(product);
        } 
      });
      return;
    }

    // If it has no options add to basket
    if (product.options.length == 0 && product.sizes.length == 0 && product.extras.length == 0) {
      await this.basketService.addToBasket(product);
      return;
    }

    // If we are on a desktop device, we want to not navigate to the new page and instead
    // show the details on the main page
    if (this.deviceService.isDesktop()) {
      this.selectedProduct = product;
      return;
    }

    // Redirect to new page with selected product
    this.router.navigate(['menu/product', product.id]);
  }

  toggleCategory(category) {
    if (category.expanded == null)
      category.expanded = false;

    category.expanded = !category.expanded;
  }
  
  onSelected(id) {
    this.selectedProduct = null;
    setTimeout(() => {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  }

  onProductCompleted() {
    this.selectedProduct = null;
  }

  onSearch() {
    this.categories = this.allCategories.filter(a => a.category.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
  }
}
