import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'price'
})
@Injectable({
  providedIn: 'root'
})
export class PricePipe implements PipeTransform {
  transform(value: number, free: boolean, extraSurplus: number = 0, showSurplus: boolean = true) {
    if (!showSurplus)
      return "";

    if (free || value == 0) {
      return " - Free";
    }
    
    value += extraSurplus;

    return "- £" + value.toFixed(2);
  }
}