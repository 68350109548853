import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { BasketService } from 'src/app/services/basket.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() product: any;
  @Input() option: any;
  @Input() isMealDeal: boolean = false;
  @Output() productCost = new EventEmitter<any>();
  @Output() productCompleted = new EventEmitter<any>();

  baseCost: number = 0;
  cost: number = 0;
  extraSurplus: number = 0;

  additionalSizes = [];
  selectedSize = null;
  selectedAdditionalSize = null;

  initial: boolean = true;
  showExtraSurplus: boolean = true;

  foodUrl: string = "";
  includesImages: boolean = false;
  appId: number;

  constructor(private basketService: BasketService, private toastr: ToastrService, private config: AppConfigService) { }

  ngOnInit(): void {
    this.foodUrl = this.config.data.food_url;
    this.includesImages = this.config.data.includes_images;
    if (this.config.data.app_id == 175)
      this.includesImages = false;
      
    this.appId = this.config.data.app_id;
    
    // Deep clone the product 
    //this.product = JSON.parse(JSON.stringify(this.product));
    
    if (this.option != null) {
      this.showExtraSurplus = this.option.show_Extra_Surplus;
    }

    if (this.product.sizes == null) this.product.sizes = [];
    if (this.product.extras == null) this.product.extras = [];

    this.baseCost = this.product.cost;

    if (this.product.sizes.length > 0) {
      this.product.sizes = _.orderBy(this.product.sizes, ['surplus'], ['asc']);
      for (let size of this.product.sizes) {
        size.sizes = _.orderBy(size.sizes, ['surplus'], ['asc']);
      }

      this.selectSize(this.product.sizes[0]);
    }


    this.product.extras = _.orderBy(this.product.extras, ['position'], ['asc']);
    for (let extraCategory of this.product.extras) {
      if (this.appId == 181)
        extraCategory.extras = _.orderBy(extraCategory.extras.filter(a => !a.eposOnly), ['name'], ['asc']);
      else
        extraCategory.extras = _.orderBy(extraCategory.extras.filter(a => !a.eposOnly), ['position'], ['asc']);
    }

    this.initial = false;
    this.updateProductCost();
  }


  updateProductCost() {
    // Stop it being called during the initialisation process
    if (this.product == null || this.initial)
      return;

    var addCost = this.option == null ? true : this.option.show_Surplus;
    var addExtraCost = this.option == null ? true : this.option.show_Extra_Surplus;

    this.cost = this.basketService.calculateProductCost(this.product, this.selectedSize, this.selectedAdditionalSize, this.product.extras, addCost, addExtraCost);
    this.productCost.emit({
      cost: this.cost,
      productId: this.product.id,
      optionId: this.option == null ? null : this.option.id
    });
  }

  selectSize(size) {
    this.selectedAdditionalSize = null;
    
    this.selectedSize = size;
    this.extraSurplus = size.extra_Surplus;
    this.additionalSizes = [];
    if (size.sizes.length > 0) {
      this.additionalSizes = size.sizes;
      if (this.additionalSizes.length > 0) {
        this.selectedAdditionalSize = this.additionalSizes[0];
      }
    }

    this.updateProductCost();
  }

  selectAdditionalSize(size) {
    this.selectedAdditionalSize = size;

    this.updateProductCost();
  }

  toggleExtra(extra, extraCategory, e) {
    if (extraCategory.maximum == 1) {
      for (let e of extraCategory.extras) {
        e.selected = false;
      }
    }

    if (extra.selected == null)
      extra.selected = false;

    if (this.appId == 181) {
      if (!extra.selected && this.exceededMaximumExtras(extraCategory)) {
        this.toastr.error("Only allowed to select a maximum of " + extraCategory.maximum + " extras")
        e.preventDefault();
        return;
      }
    }

    if (extra.cost == 0 || extra.free) {
      extra.selected = !extra.selected;
      this.updateProductCost();
      return;
    }

    // if the extra is not selected currently, it means we are going into the 
    // selected phase 
    if (!extra.selected && this.exceededMaximumExtras(extraCategory)) {
      this.toastr.error("Only allowed to select a maximum of " + extraCategory.maximum + " extras")
      e.preventDefault();
      return;
    }

    extra.qty = 1;
    extra.selected = !extra.selected;

    this.updateProductCost();
  }

  addQty(extra, extraCategory) {
    if (this.exceededMaximumExtras(extraCategory)) {
      this.toastr.error("Only allowed to select a maximum of " + extraCategory.maximum + " extras")
      return;
    }

    extra.selected = true;
    extra.qty++;
    this.updateProductCost();
  }

  removeQty(extra, extraCategory) {
    extra.qty--;

    if (extra.qty <= 0) {
      extra.selected = false;
      extra.qty = 0;
    }
    this.updateProductCost();
  }

  exceededMaximumExtras(extraCategory) {
    var maximum = extraCategory.maximum;
    if (maximum == 0)
      return false;

    var extras = extraCategory.extras.filter(a => a.selected == true);
    if (extras.length == 0)
      return false;

    var costExtras = []
    if (this.appId == 181 || this.appId == 190)
      costExtras = extras.filter(a => !a.free && !a.isFree);
    else
      costExtras = extras.filter(a => a.cost > 0 && !a.free && !a.isFree);

    for (let extra of extras) {
      if (extra.selected && extra.qty == 0) {
        extra.qty = 1;
      }
    }

    if (costExtras.length > 0) {
      var qty = costExtras.map(a => a.qty).reduce((prev, next) => prev + next);
      if (qty >= maximum) {
        return true;
      }
    }

    return false;
  }

  // This is only for non meal deal items
  addToBasket() {
    //var product = JSON.parse(JSON.stringify(this.product));
    this.product.selected_size = this.selectedSize;
    this.product.additional_size = this.selectedAdditionalSize;

    var selected_extra_categories = [];
    for (let extraCategory of this.product.extras) {
      if (extraCategory.extras.filter(a => a.selected).length == 0)
        continue;

      var selected_extras = extraCategory.extras.filter(a => a.selected);
      extraCategory.extras = selected_extras;

      selected_extra_categories.push(extraCategory);
    }

    this.product.selected_extras = selected_extra_categories;
    this.product.cost = this.basketService.calculateProductCost(this.product, this.selectedSize, this.selectedAdditionalSize, this.product.extras, true, true);
    this.productCompleted.emit(this.product);
  }

  // Raw to your door
  getExtraBaseCost(extra) {
    if (extra.cost == 0) {
      return "£" + this.product.cost.toFixed(2);
    } else {
      return "£" + (this.product.cost + extra.cost).toFixed(2);
    }
  }

}
