import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable()
export class OrderService {
  private app = null;

  constructor(private http: HttpClient, private config: AppConfigService) {

  }


  public placeOrder(order) {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.data.url + "api/v2/order/post", order).subscribe((data: any) => {
        resolve(data);

        localStorage.setItem("CurrentOrder", JSON.stringify(data));
      }, error => {
        reject(error.error);
      })
    })
  }

  public saveLocalOrder(order) {
    localStorage.setItem("CurrentOrder", JSON.stringify(order))
  }

  public getLocalOrder() {
    var orderJson = localStorage.getItem("CurrentOrder");
    if (orderJson == null || orderJson == "")
      return null;

    return JSON.parse(orderJson);
  }
  
  public getRemoteOrder(orderId: number) {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.data.url + "api/order/getorder?order_id=" + orderId).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      })
    })
  }

  public getStripePaymentIntent(appGuid: string, total: number, additionalStoreId: number) : Promise<string> {
    return new Promise((resolve, reject) => {
      var url = this.config.data.url + "api/v2/stripe/createpaymentintent?appGuid=" + appGuid + "&cost=" + total;
      if (additionalStoreId != null)
        url += "*additionalStoreId=" + additionalStoreId;
        
      this.http.get<any>(url).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      })
    })
  }
}