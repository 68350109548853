import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Output() selected: EventEmitter<any> = new EventEmitter();
  categories = [];
  categoryHeight = 0;
  isDesktop: boolean = false;

  constructor(private categoryService: CategoryService, private deviceService: DeviceDetectorService) { 
    this.isDesktop = deviceService.isDesktop();
  }

  ngOnInit(): void {
    this.categoryService.getAll().then((data) => {
      this.categories = data.filter(e => e.eposOnly == false);
    });

    this.updateScrollableContainer(window.innerHeight);
  }

  scroll(id) {
    this.selected.emit(id);
    // console.log(`scrolling to ${id}`);
    // let el = document.getElementById(id);
    // el.scrollIntoView();
  }

  updateScrollableContainer(height) {
    if (this.deviceService.isDesktop()) {
      this.categoryHeight = height - 50;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateScrollableContainer(window.innerHeight);
  }
}
