import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SnotifyService } from 'ng-snotify';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  isLoading: boolean = false;
  email: string = "";
  name: string = "";
  password: string = "";
  password2: string = "";
  phone: string = "";
  addressLine1: string = "";
  postcode: string = "";

  postcodeSearch: string = "";
  selectedPostcode: string = "";

  addresses = [];

  constructor(private appService: AppService, private snotifyService: SnotifyService, private config: AppConfigService, private http: HttpClient, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  validate(): boolean { 
    if (this.email == "" || this.email == null) {
      this.snotifyService.warning("Enter email");
      return false;
    }

    if (this.password == "" || this.password == null) {
      this.snotifyService.warning("Enter password");
      return false;
    }

    if (this.name == "" || this.name == null) {
      this.snotifyService.warning("Enter name");
      return false;
    }

    if (this.phone == "" || this.phone == null) {
      this.snotifyService.warning("Enter phone");
      return false;
    }

    if (this.phone.length < 11) {
      this.snotifyService.warning("Enter valid phone //#endregion");
      return false;
    }

    if (this.addressLine1 == "" || this.addressLine1 == null) {
      this.snotifyService.warning("Enter address line 1");
      return false;
    }

    if (this.postcode == "" || this.postcode == null) {
      this.snotifyService.warning("Enter postcode");
      return false;
    }

    if (this.password != this.password2) {
      return false;
    }

    return true;
  }

  searchAddresses() {
    if (this.postcodeSearch == undefined || this.postcodeSearch == "")
      return;

    this.isLoading = true;

    this.appService.getAddress(this.postcodeSearch).then((data) => {
      this.addresses = data.addresses;
      this.selectedPostcode = JSON.stringify(data.addresses[0]);

      this.isLoading = false;
    })
  }

  selectAddress(value) {
    value = value.replace("\"", "");
    let data = value.toString().split(",");
    this.addressLine1 = data[0];
    this.postcode = this.postcodeSearch.toUpperCase();
    this.postcodeSearch = "";

    this.addresses = [];
  }

  onRegister() {
    if (!this.validate()) {
      return;
    }

    this.isLoading = true;

    var dto = {
      email: this.email,
      password: this.password,
      phone: this.phone,
      address_Line_1: this.addressLine1,
      postcode: this.postcode,
      app_Id: this.config.data.app_id
    };

    this.http.post(this.config.data.url + "api/account/register", dto).subscribe(data => {
      this.authService.setUser(data);
      this.router.navigate(['/menu']);

      this.isLoading = false;
    }, error => {
      this.snotifyService.error("Email already exists");
      //this.errorMessage = "Invalid email or password";
      this.isLoading = false;
    })  
  }
}
