import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  app;

  constructor(private appService: AppService) { 
    appService.getApp().then((app) => {
      this.app = app;
    });
  }

  ngOnInit(): void {
  }

}
