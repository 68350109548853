<h2 mat-dialog-title>Closed</h2>
<mat-dialog-content class="mat-typography">
  <p>Sorry we are closed right now. Please check our opening times in the contact page.</p>

  <div *ngIf="preOrder">
    <div class="preorder-actions">
      <button (click)="setDelivery(false)" [ngClass]="{'preorder-selected': isDelivery == false}">Collection</button>
      <button (click)="setDelivery(true)" [ngClass]="{'preorder-selected': isDelivery == true}">Delivery</button>
    </div>
    
    <div *ngIf="isDelivery != null">
      <p style="margin-top: 10px;">Set your pre-order time:</p>
      <app-eta-dialog [isDelivery]="isDelivery" [etaTime]="etaTime" (etaSet)="updateEtaTime($event)"></app-eta-dialog>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions" *ngIf="!preOrder">
  <button *ngIf="allowPreOrders" mat-button class="order-later" (click)="preOrder = true">PREORDER</button>
  <div class="spacer"></div>
  <button mat-button mat-dialog-close cdkFocusInitial>CANCEL</button>
</mat-dialog-actions>

<mat-dialog-actions class="dialog-actions" *ngIf="preOrder">
  <button class="order-later" mat-button mat-dialog-close cdkFocusInitial>CANCEL</button>
  <div class="spacer"></div>
  <button mat-button *ngIf="isDelivery != null && etaTime != null" (click)="setPreOrder()">PREORDER</button>
</mat-dialog-actions>