<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="content">
  <div class="login-header">
    <i class="fas fa-user-shield"></i>
    <h3>Register</h3>
  </div>

  <form (ngSubmit)="onRegister()" *ngIf="addresses.length == 0">
    <div class="form-group">
      <label>Email</label>
      <input type="text" class="form-control" [(ngModel)]="email" name="email"/>
    </div>

    <div class="form-group">
      <label>Password</label>
      <input type="password" class="form-control" [(ngModel)]="password" name="password"/>
    </div>

    <div class="form-group">
      <label>Confirm Password</label>
      <input type="password" class="form-control" [(ngModel)]="password2" name="password2"/>
    </div>
    
    <div class="form-group name-block">
      <label>Name</label>
      <input type="text" class="form-control" [(ngModel)]="name" name="name"/>
    </div>

    <div class="form-group">
      <label>Telephone</label>
      <input type="text" class="form-control" [(ngModel)]="phone" name="phone"/>
    </div>

    <div class="postcode-lookup">
      <div class="form-group">
        <label>Postcode Lookup</label>
        <div class="form-group-flex">
          <input type="text" class="form-control" [(ngModel)]="postcodeSearch" name="postcodeSearch"/>
          <input type="button" value="Search" (click)="searchAddresses()"/>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>Address</label>
      <input type="text" class="form-control" [(ngModel)]="addressLine1" name="addressLine1"/>
    </div>

    <div class="form-group">
      <label>Postcode</label>
      <input type="text" class="form-control" [(ngModel)]="postcode" name="postcode"/>
    </div>
  

    <input type="submit" class="submit-btn" value="Register"/>
  </form>

  <div class="address-block">
    <ul>
      <li *ngFor="let address of addresses"><a (click)="selectAddress(address)">{{ address }}</a></li>
    </ul>
  </div>

  <a class="reroute-link" routerLink="/account/login">Click here to Login</a>
</div>
