import { Component, OnInit, ChangeDetectorRef, APP_ID, Input, Output, EventEmitter } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BasketService } from 'src/app/services/basket.service';
import { update } from 'lodash';
import { Location } from '@angular/common';
import { ExternalExpr } from '@angular/compiler';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppConfigService } from 'src/app/services/app-config.service';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  @Input() product: any = null;
  @Output() productCompleted = new EventEmitter<any>();
  isLoading: boolean = true;
  cost: number = 0;
  costs = [];
  isMealDeal: boolean = false;

  foodUrl: string = "";
  includesImages: boolean = false;
  appId: number;

  constructor(private productService: ProductService, private route: ActivatedRoute, private toastr: ToastrService, private basketService: BasketService, 
    private location: Location, private cdr: ChangeDetectorRef, private deviceService: DeviceDetectorService, private config: AppConfigService) { 

  }

  async ngOnInit(): Promise<any> {
    this.isLoading = true;
    this.foodUrl = this.config.data.food_url;
    this.includesImages = this.config.data.includes_images;
    this.appId = this.config.data.app_id;


    this.route.params.subscribe(async (params) => {
      const id = params["id"];
      if (id != null) {
        // this.product = await this.productService.get(id);
        this.product = await this.productService.get(id);
      }

      this.product = JSON.parse(JSON.stringify(this.product));

      this.isMealDeal = this.product.options.length > 0;
      this.cost = this.product.cost;
      this.isLoading = false;

      for (let option of this.product.options) {
        if (option.product_Option_Products.length == 1) {
          option.selectedProduct = option.product_Option_Products[0].product;
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  // onProductCompleted(product) {
  //   console.log(product);
  //   // if (this.product.options.length > 0) {
  //   //   // This is a meal deal so we want to associate the product 
  //   // }
  
  // }

  // This is only used for meal deals
  onProductCost(evt) {
    var option = this.product.options.find(a => a.id == evt.optionId);
    if (option != null) {
      if (option.show_Surplus) {
        option.selectedProduct.cost = evt.cost;
      } else {
        option.selectedProduct.cost = 0;
      } 
    }
    this.updateMealDealCosts();
  }
  
  onOptionChange($evt) {
    this.product.selectedProduct = JSON.parse(JSON.stringify($evt));
    this.updateMealDealCosts();
  }

  // This is only for single items and not meal deals
  onProductCompleted($evt) {
    this.product = $evt;

    this.addProductToBasket(); 
  }

  updateMealDealCosts() {
    var productCost = this.product.cost;
    for (let productOption of this.product.options) {
      if (productOption.selectedProduct != null && productOption.selectedProduct != undefined) {
        productCost += productOption.selectedProduct.cost;
      }
    }

    this.cost = productCost;
  }

  validate() {
    if (this.isMealDeal) {
      for (let productOption of this.product.options) {
        if (productOption.selectedProduct == null || productOption.selectedProduct == undefined) {
          this.toastr.error("Please select all options");
          return false;
        } 

        for (let extraCategory of productOption.selectedProduct.extras) {
          var selected_extras_len = extraCategory.extras.filter(a => a.selected).length;
          if (extraCategory.maximum > 0 && selected_extras_len == 0) {
            this.toastr.error("Please select an extra for " + extraCategory.name);
            return;
          }
        }
      }
    }

    var selected_extra_categories = [];
    for (let extraCategory of this.product.extras) {
      var selected_extras_len = extraCategory.extras.filter(a => a.selected).length;
      if (extraCategory.maximum > 0 && selected_extras_len == 0) {
        this.toastr.error("Please select an extra for " + extraCategory.name);
        return;
      }
    }

    return true;
  }


  async addProductToBasket() {
    await this.basketService.addToBasket(this.product);

    if (this.deviceService.isDesktop()) {
      this.productCompleted.emit();
      return;
    }

    this.location.back();
  }

  async addMealDealToBasket() {
    if (!this.validate())
      return;

    var existingOptions = JSON.parse(JSON.stringify(this.product.options));

    for (let existingOption of existingOptions) {
      //console.log(existingOption);
      var selected_extra_categories = [];
      for (let extraCategory of existingOption.selectedProduct.extras) {
        if (extraCategory.extras.filter(a => a.selected).length == 0)
          continue;
  
        var selected_extras = extraCategory.extras.filter(a => a.selected);
        extraCategory.extras = selected_extras;
  
        selected_extra_categories.push(extraCategory);
      }

      existingOption.selectedProduct.selected_extras = selected_extra_categories;
    }
   
    var selected_extra_categories = [];
    for (let extraCategory of this.product.extras) {
      if (extraCategory.extras.filter(a => a.selected).length == 0)
        continue;

      var selected_extras = extraCategory.extras.filter(a => a.selected);
      extraCategory.extras = selected_extras;

      selected_extra_categories.push(extraCategory);
    }

    this.product.selected_extras = selected_extra_categories;

    this.product.cost = this.cost;
    this.product.selected_options = existingOptions;

    await this.basketService.addToBasket(this.product);

    if (this.deviceService.isDesktop()) {
      this.productCompleted.emit();
      return;
    }

    this.location.back();
  }
}
