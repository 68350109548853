import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import * as moment from 'moment';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  interval;
  order;
  eta;
  app;

  constructor(private orderService: OrderService, private appService: AppService) {
    this.order = this.orderService.getLocalOrder();
    if (this.order.connectedAccountId != null && this.order.connectedAccountId != '') {
      this.order.cost += 0.5;
    }
    
    appService.getApp().then((app) => {
      this.app = app;
    });
  }

  ngOnInit(): void {
    this.startTimer();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.orderService.getRemoteOrder(this.order.id).then((newOrder) => {
        this.order = newOrder;
        if (this.order.connectedAccountId != null && this.order.connectedAccountId != '') {
          this.order.cost += 0.5;
        }
        this.orderService.saveLocalOrder(this.order);

        if (this.order.order_State >= 3) {
          clearInterval(this.interval);
        }

        if (this.order.etA_Minutes != null) {
          var dueDate = moment(this.order.order_Placed_At).add(this.order.etA_Minutes, 'minutes');

          var duration = moment.duration(dueDate.diff(moment()));
          this.eta = duration.asMinutes().toFixed(0);
          if (this.eta < 0) {
            this.eta = 0;
          }
        }
      });
    }, 5000);
  }
}