<div class="basket-summary-block" *ngIf="app">
  <h3>Your Basket</h3>

  <div class="allergy-advice-block">
    <p>
      <span>Allergy Advice: </span> If you have an allergy that could harm your health we strongly advise to contact the restaurant before you place any order.
    </p>
  </div>

  <div class="delivery-notice-block" *ngIf="app.minimum_Order > 0 && subTotal  < app.minimum_Order">
    <i class="fas fa-info-circle"></i>
    <span>Spend at least £{{ app.minimum_Order }} to get delivery.</span>
  </div>

  <ul style="padding: 10px;" class="products-wrapper">
    <li *ngFor="let product of products" class="product-outer-block">
      <div class="product-block">
        <div class="product-info">
          <div class="product-name">{{ product.name }}</div>
  
          <!-- Options -->
          <div class="product-options">
            <div *ngFor="let option of product.options">
              <div class="option">{{ option.name }}</div>
              <div *ngIf="option.extras.length > 0">
                <div class="product-extras product-option-extras">
                  <div *ngFor="let extraCategory of option.extras">
                    <div class="extra" *ngFor="let extra of extraCategory.extras">
                      + {{ extra.name }}
                      <!-- <span *ngIf="!extra.free">({{ extra.cost | currency: 'GBP' }})</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div *ngIf="product.extras.length > 0">
            <p class="product-extra-title" >EXTRAS</p>
            <div class="product-extras" >
              <div *ngFor="let extraCategory of product.extras">
                <div class="extra" *ngFor="let extra of extraCategory.extras">
                  <span *ngIf="extra.qty > 0">{{ extra.qty }}x - </span>
                  <span *ngIf="extra.qty == 0">1x - </span>
                  {{ extra.name }} ({{ extra.cost | currency: 'GBP' }})
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="product-cost">
          <div class="cost">
            {{ product.cost | currency: 'GBP' }}
          </div>
  
          <div class="qty-block">
            <i class="fas fa-minus-circle qty-minus-selected" (click)="removeQty(product)"></i>
            <div class="qty">
              {{ product.qty }}
            </div>
            <i class="fas fa-plus-circle qty-plus-selected" (click)="addQty(product)"></i>
          </div>
        </div>
      </div>

      <div class="product-notes">
        <input type="text" placeholder="Notes..." [(ngModel)]="product.notes" />
      </div>
    </li>
  </ul>

  <div *ngIf="app.promotions_Cash != null && app.promotions_Cash.length > 0">
    <div class="promotion-block" *ngFor="let promotion of app.promotions_Cash">
      <div class="icon cross-icon" *ngIf="promotion.selected == false"><i class="fa fa-times" aria-hidden="true"></i></div>
      <div class="icon tick-icon" *ngIf="promotion.selected == true"><i class="fa fa-check" aria-hidden="true"></i></div>
      <div class="promotion-cost">£{{ promotion.cost.toFixed(2) }}</div>
      <div class="promotion-name">{{ promotion.product.name }}</div>
    </div>
  </div>

  <!-- <div class="promo-block">
    <label>Enter promotional code</label>
    <div class="promo-inner-block">
      <input type="text" [(ngModel)]="promotionalCode"  />
      <button>SUBMIT</button>
    </div>
  </div> -->
  
  <div class="totals-block" *ngIf="showTotals">
    <div class="basket-totals" *ngIf="products.length > 0 && subTotal != null">
      <div class="total-block">
        <div class="total-name">Subtotal:</div>
        <div class="total-cost"> {{ subTotal | currency: 'GBP' }}</div>
      </div>
    
      <div class="total-block">
        <div class="total-name">Discount:</div>
        <div class="total-cost"> {{ discount | currency: 'GBP' }}</div>
      </div>
    
      <div class="total-block" *ngIf="app.connectedAccountId != null && app.connectedAccountId != ''">
        <div class="total-name">Service Fee:</div>
        <div class="total-cost">£0.50</div>
      </div>

      <div class="total-block">
        <div class="total-name">Total:</div>
        <div class="total-cost total"> {{ total | currency: 'GBP' }}</div>
      </div>
    </div>
    
    <div class="action-buttons-container">
      <a class="cancel-btn" *ngIf="products.length > 0 && subTotal != null" (click)="cancel()">CANCEL</a>
      <a class="checkout-btn" *ngIf="products.length > 0 && subTotal != null" (click)="checkout()">CHECKOUT</a>
    </div>
  </div>

  <p class="allergy-info" *ngIf="allergyInfo">{{ allergyInfo }}</p>
</div>