import { Component, ViewChild } from '@angular/core';
import { AppService } from './services/app.service';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild(NavbarComponent) navBarComponent : NavbarComponent;
  @ViewChild(SidebarComponent) sideBarComponent : SidebarComponent;

  title = 'Portal';
  isLoading: boolean = true;
  menuOpen: boolean = false;

  constructor(private appService: AppService) {
    this.appService.getApp().then((app) => {
      this.isLoading = false;
    });
  }
  

  hideNav() {
    this.navBarComponent.hideMenu();
  }

  updateOrder() {
    this.sideBarComponent.updateOrder();
  }

  navStateChanged(opened) {
    if (!opened) {
      this.navBarComponent.hideMenu();
    }
  }
}
