import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasketService } from 'src/app/services/basket.service';
import { AppService } from 'src/app/services/app.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basket-summary',
  templateUrl: './basket-summary.component.html',
  styleUrls: ['./basket-summary.component.scss']
})
export class BasketSummaryComponent implements OnInit {
  @Input() showTotals: boolean = true;
  @Output() onCancel = new EventEmitter();
  app = null;
  products = [];

  subTotal: 0;
  discount: 0;
  deliveryCost: 0;
  total: 0;

  allergyInfo: string;

  promotionalCode: string;

  constructor(private appService: AppService, private basketService: BasketService, private config: AppConfigService, private router: Router) { 
    this.allergyInfo = config.data.allergy_info;

    appService.getApp().then((app) => {
      this.app = app;

      this.setPromotionalItems();
    });

    basketService.products.subscribe((prods) => {
      if (prods == null)
        return;

      this.products = prods;
    });

    basketService.costs.subscribe((costs) => {
      if (costs == null) {
        return;
      }
      
      this.subTotal = costs.subTotal;
      this.discount = costs.discount;
      this.deliveryCost = costs.deliveryCost;
      this.total = costs.total;

      this.setPromotionalItems();
    });
  }

  setPromotionalItems() {
    if (this.app == null) return;

    if (this.app.promotions_Cash != null && this.app.promotions_Cash.length > 0) {
      // Reset the promos
      for (let promo of this.app.promotions_Cash) promo.selected = false;

      for (let promo of this.app.promotions_Cash.sort((a, b) => b.cost - a.cost)) {
        if (this.total >= promo.cost) {
          promo.selected = true;
          break;
        }
      }
    }
  }

  ngOnInit(): void {
  }

  async addQty(prod) {
    await this.basketService.addQty(prod);
  }

  async removeQty(prod) {
    await this.basketService.removeQty(prod);
  }

  cancel() {
    this.onCancel.emit();
  }

  checkout() {
    // Update any notes fields
    this.basketService.updateProducts(this.products);

    this.router.navigate(['/checkout'])
  }
}
