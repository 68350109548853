<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="products-wrapper">
  <div class="products-content">
    <div class="products-outer-block">
      <app-categories class="categories" (selected)="onSelected($event);"></app-categories>

      <div class="products-block" *ngIf="selectedProduct == null">
        <div class="search-block">
          <div class="logo">
            <img src="../../../assets/logo.png" />
          </div>
          
          <div class="search-input">
            <i class="fas fa-search"></i>
            <input type="search" placeholder="Search..." [(ngModel)]="search" (ngModelChange)="onSearch()"/>
          </div>
        </div>

        <div class="category-block" *ngFor="let category of categories">
          <div [id]="category.products[0].parent_Category.slug" class="offset"></div>
          <div class="title">
            <h5 (click)="toggleCategory(category)">
              <span>{{ category.category }}</span>
              <i *ngIf="category.expanded" class="fas fa-chevron-down"></i>
              <i *ngIf="!category.expanded" class="fas fa-chevron-right"></i>
            </h5>
            <ul *ngIf="category.expanded" [@enterAnimation]>
              <li *ngFor="let product of category.products">
                <div class="product-block" (click)="selectProduct(product)">
                  <img *ngIf="includesImages && product.parent_Category != null" [src]="foodUrl + appId + '/products/' + product.parent_Category.id + '/' + product.id + '.jpg'"/>
                  <!-- <img [src]="" -->
                  <div class="product-info-block">
                    <div class="name">
                      <span>{{ product.name }}</span> 
                      <div class="red" *ngIf="getChillis(product) != ''" style="margin-right: 5px;" [innerHTML]="getChillis(product)"></div> 
                      <div class="green" *ngIf="getVeg(product) != ''" style="margin-right: 5px;" [innerHTML]="getVeg(product)"></div>
                      <div class="green" *ngIf="getNuts(product) != ''" style="margin-right: 5px; width: 18px;" [innerHTML]="getNuts(product)"></div>
                      <div class="green" *ngIf="getGluten(product) != ''" style="margin-right: 5px; width: 18px" [innerHTML]="getGluten(product)"></div>
                    </div>
                    <div class="description">{{ product.description }}</div>
                    <div class="cost" *ngIf="product.cost == 0">Prices Vary</div>
                    <div class="cost" *ngIf="product.cost > 0">{{ product.cost | currency: 'GBP' }}</div>
                  </div>

                  <div class="product-add-block">
                    <i class="fas fa-shopping-cart"></i>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="allergies-block">
          <div class="allergy">
            <span>Suitable For Vegan</span>
            <i class='fas fa-leaf green'></i>
          </div>

          <div class="allergy">
            <span>Contains Gluten</span>
            <img style="width: 18px;" src='/assets/gluten.png'/>
          </div>

          <div class="allergy">
            <span>Contains Nuts</span>
            <img style="width: 18px;" src='/assets/nuts.png'/>
          </div>
        </div>
      </div>

      <div class="products-block" *ngIf="selectedProduct != null">
        <div class="back-arrow" (click)="selectedProduct = null">
          <span><i class="fas fa-arrow-left"></i>Back</span>
        </div>
        <app-product-details [product]="selectedProduct" (productCompleted)="onProductCompleted()"></app-product-details>
      </div>

      <app-basket-summary class="basket"></app-basket-summary>
    </div>
  </div>
</div>