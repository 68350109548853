<nav class="nav" *ngIf="app">
  <div class="top-nav">
    <!-- <span>Call us for ordering</span> -->
    <div class="menu" (click)="toggleMenu()">
      <i *ngIf="!isMenuOpen" class="fas fa-bars"></i>
      <i *ngIf="isMenuOpen" class="fas fa-arrow-left"></i>
    </div>

    <div class="app-info">
      <span>{{ app.name }}</span>
      <span class="phone"><i class="fas fa-mobile-alt"></i>{{ app.phone }}</span>
    </div>

    <div class="user" (click)="login()">
      <i *ngIf="!isLoggedIn" class="far fa-user"></i>
      <i *ngIf="isLoggedIn" class="fas fa-user-check"></i>
    </div>
  </div>
</nav>