<div class="content">
  <h1>Privacy Policy</h1>

  <h2>Introduction</h2>
  <p>
    At Workflow Digital Ltd we are committed to building the world’s greatest food community, and that begins with protecting the privacy of everyone in our community. 
    This means that we’ll protect the personal information of all visitors who access our websites or services through any mobile application, 
    platform or device (collectively, the “Services”).This privacy notice (“Privacy Notice”) explains how Workflow Digital Ltd, our subsidiaries and affiliates collect, 
    share, and use your personal information. You’ll also find information about how you can exercise your privacy rights. By using our Services you agree to 
    Workflow Digital Ltd using your personal information as described in this Privacy Notice. The terms “we”, “us” or “our” are each intended as reference to Workflow Digital Ltd
    and any terms not defined in this Privacy Notice are defined in our Terms.
  </p>

  <h3>1. Information we collect from you and why</h3>
  <p>
    Information that you provide voluntarily:
  </p>
  <ul>
    <li>Registration information when you create an account so we can:</li>
    <ul>
      <li>create your account so you can place Orders under our Terms</li>
      <li>identify you when you sign-in to your account</li>
      <li>notify you of changes or updates to our Services.</li>
    </ul>
  </ul>

  <ul>
    <li>Transaction Information when you place an Order with a Restaurant in order to:</li>
    <ul>
      <li>process your Order and to bill you (though note, Workflow Digital Ltd never stores your credit card information in our systems);</li>
      <li>communicate your Order to the Restaurant;</li>
      <li>send you status updates on your Order;</li>

      <li>reply to your Order queries and questions and to resolve problems;</li>
      <li>carry out analysis and research to develop and improve our Services; and</li>
      <li>protect you and the Services by seeking to detect and prevent fraud or other acts in breach of our Terms or policies relevant to the Services.</li>
    </ul>
  </ul>

  <h3>2. Who we share personal information with</h3>
  <p>
    We may share your personal information with the following recipients (and whenever we do, we will ensure that we have appropriate security and contractual safeguards in place to protect it):
  </p>
  <ul>
    <li>Restaurants that you have placed an Order with for processing and delivery;</li>
    <li>Any law enforcement or regulatory body, government agency, court or other third party where we believe disclosure is necessary under applicable law or regulations;</li>
    <li>Any other person provided that you have given your consent. We will never sell, distribute or otherwise share your personal information unless we have your permission.</li>
  </ul>

  <h3>3. Legal basis for processing personal information</h3>
  <p>
    Workflow Digital Ltd will always make sure we have a legal basis to collect and use your personal information. 
    The legal basis we rely on will change depending on the type of information and the context in which we collect it.
    Our main reason for collecting and using your personal information is to perform our contract with you 
    (i.e. to make sure you get your Order when and where you want it), but we may also process it where it is in our legitimate business interests to do so.
  </p>

  <h3>4. Security</h3>
  <p>
    Workflow Digital Ltd places great importance on protecting your information from and against unauthorised access and against unlawful processing, accidental loss, 
    destruction and damage. We implement appropriate technical and organisational measures to safeguard such information.
  </p>

  <h3>5. Data Retention</h3>
  <p>
    Workflow Digital Ltd will retain your personal information for no longer than is necessary to fulfil the purposes described in this Privacy Notice. We may also retain certain elements of your personal information for a period after you delete or deactivate your account for our 
    legitimate operations such as record keeping and to comply with our legal obligations. Whenever we retain your information we will do so in compliance with applicable laws.
  </p>

  <h3>6. Your data protection rights</h3>
  <p>
    You can contact us to ask us to delete your personal information, restrict its processing or request that it be ported to a third party. You also have the right to unsubscribe from the marketing 
    communications we send you by using the unsubscribe functionality in any marketing communication you receive from us or by amending your profile accordingly.
  </p>
</div>

