import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  facebook: string;
  twitter: string;
  instagram: string;

  constructor(private config: AppConfigService) { 
    this.facebook = config.data.facebook;
    this.twitter = config.data.twitter;
    this.instagram = config.data.instagram;
  }

  ngOnInit(): void {
  }

}
