import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'openingtime'
})
@Injectable({
  providedIn: 'root'
})
export class OpeningTimePipe implements PipeTransform {
  transform(opening_Time: any) {
    if (opening_Time.isClosed)
      return "Closed";

    // console.log(store);
    // if (opening_Time.isClosed && (store == null || store.id == 0))
    //   return "Closed";

    // if (store != null) {
    //   if (store.additional_Store_Opening != null && store.additional_Store_Opening.length > 0) {
    //     var day = store.additional_Store_Opening.find(a => a.day == dayIdx);
    //     var opens = moment(opening_Time.opens, "HH:mm").add(day.opening_Hours, 'hours').format("HH:mm");
    //     var closes = moment(opening_Time.closes, "HH:mm").add(day.closing_Hours, 'hours').format("HH:mm");

    //     return opens + "<br/>" + closes;
    //   }
    // }

    //return opening_Time.opens + "<br/>" + opening_Time.closes;
    return opening_Time.opens + " - " + opening_Time.closes;
  }
}