import { EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-eta-dialog',
  templateUrl: './eta-dialog.component.html',
  styleUrls: ['./eta-dialog.component.scss']
})
export class EtaDialogComponent implements OnInit, OnChanges {

  @Input() isDelivery: boolean;
  @Input() etaTime: string;

  @Output() etaSet = new EventEmitter<string>();

  min;
  max;

  constructor(private appService: AppService) { }

  ngOnInit(): void {
  }

  async calculateMinMax() {
    var app = await this.appService.getApp();
    var openingTime = app.opening_Times[moment().day()];
    var deliveryTime = app.delivery_Times[moment().day()];

    if (!this.isDelivery || deliveryTime.opens == "00:00") {
      this.min = openingTime.opens;
      this.max = openingTime.closes;
    } else {
      this.min = deliveryTime.opens;
      this.max = deliveryTime.closes;
    }

    if (this.max == "00:00") {
      this.max = "23:00";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.calculateMinMax();
  }

  updateTime(e) {
    this.etaSet.emit(e);
  }
}
