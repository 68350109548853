<div class="content">
  <ul *ngIf="isDesktop" [style.height.px]='categoryHeight'>
    <li *ngFor="let category of categories">
      <a (click)="scroll(category.slug)">
        <span>{{ category.name }}</span>
        <i class="fas fa-chevron-right"></i>
      </a>
    </li>
  </ul>
  <ul *ngIf="!isDesktop">
    <li *ngFor="let category of categories">
      <a (click)="scroll(category.slug)">
        <span>{{ category.name }}</span>
        <i class="fas fa-chevron-right"></i>
      </a>
    </li>
  </ul>
</div>
