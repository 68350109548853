<ng-snotify></ng-snotify>

<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<!-- <router-outlet *ngIf="!isLoading"></router-outlet> -->

<app-navbar (menuToggled)="$event == true ? sidenav.open() : sidenav.close(); updateOrder();"></app-navbar>

<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="over" class="sidenav-wrapper" [fixedInViewport]="true" (openedChange)="navStateChanged($event)">
    <div class="menu-content">
      <app-sidebar (navSelected)="hideNav()"></app-sidebar>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet *ngIf="!isLoading"></router-outlet>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>