<div class="content" *ngIf="app">
  <div class="logo">
    <img src="../../../assets/logo.png" />
  </div>

  <h1>{{ app.name }}</h1>

  <div class="content-block">
    <div class="opening-hours-table">
      <table>
        <thead>
          <tr>
            <th>Day</th>
            <th>Opens - Closes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Monday</td>
            <td [ngClass]="{'current-day': currentDay == 1 }" [innerHTML]="app.opening_Times[1] | openingtime"></td>
          </tr>
          <tr>
            <td>Tuesday</td>
            <td [ngClass]="{'current-day': currentDay == 2 }" [innerHTML]="app.opening_Times[2] | openingtime"></td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td [ngClass]="{'current-day': currentDay == 3 }" [innerHTML]="app.opening_Times[3] | openingtime"></td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td [ngClass]="{'current-day': currentDay == 4 }" [innerHTML]="app.opening_Times[4] | openingtime"></td>
          </tr>
          <tr>
            <td>Friday</td>
            <td [ngClass]="{'current-day': currentDay == 5 }" [innerHTML]="app.opening_Times[5] | openingtime"></td>
          </tr>
          <tr>
            <td>Saturday</td>
            <td [ngClass]="{'current-day': currentDay == 6 }" [innerHTML]="app.opening_Times[6] | openingtime"></td>
          </tr>
          <tr>
            <td>Sunday</td>
            <td [ngClass]="{'current-day': currentDay == 0 }" [innerHTML]="app.opening_Times[0] | openingtime"></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="contact-table">
      <div class="contact" *ngIf="app.id != 195">
        <h3>Address</h3>
        <p>{{ app.address_Line_1 }}, {{ app.postcode }}</p>
      </div>

      <div class="contact">
        <h3>Phone number</h3>
        <p>{{ app.phone }}</p>
      </div>
    </div>
  </div>

  <div style="width: 100%; margin-top: 30px;" *ngIf="app.id != 195">
    <iframe width="100%" height="600" frameborder="0" 
    scrolling="no" marginheight="0" marginwidth="0" 
    [src]="gmapsUrl | safe">
  </iframe>
  </div>
</div>
