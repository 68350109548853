<div class="content">
  <p>Dear Customers;</p>
  <p>We try to serve you best food as possible however if you think the product that we have served you does not meet your satisfaction.</p>

  <h1>REFUND POLICY:</h1>
  <p>We will fully refund you if:</p>
  <ul style="list-style-type: none; margin: 0; padding: 0;">
    <li>1) Contact us within 1 Hour after delivery</li>
    <li>2) Valid explanation is provided </li>
    <li>3) Food consumptions is less than 5% Any other terms are not excepted.</li>
  </ul>
  <p>
    We aim to serve you the highest quality. If you are not happy with your order then we may refund you part/full or credit towards your next order. 
    If you wish to make a complaint, please contact us as soon as you have received your order. We cannot accept refund requests after one hour of delivery. 
    We would processes your refund ASAP, however, the bank processes may take upto 48hrs.
  </p>

  <h1>CANCELLATION POLICY:</h1>
  <p>An order may be cancelled for a full refund as long as preparation has not been started. </p>

  <h1>PRICING &amp; AVAILABILITY</h1>
  <p>
    We try to make sure all details including descriptions and prices displayed in our website and mobile app are correct, 
    however, sometimes errors may ocur. has the right to correct this errors by reconfirming with you. 
    We can cancel and fully refund you If we are unable to confirm this order.
  </p>

  <h1>DELIVERY / COLLECTION TIMES</h1>
  <p>Times given for collection and delivery are only approximate; </p>

  <h1>CONTACT ADDRESS:</h1>
  <p>{{ app.name }}</p>
  <p>{{ app.address_Line_1 }} {{ app.postcode }}</p>
  <p>{{ app.phone }}</p>
</div>