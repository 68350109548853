<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div *ngIf="product != null">
  <div class="product-details-content">
    <div class="description-block">
      <!-- <img src="https://via.placeholder.com/512" /> -->
      <img *ngIf="includesImages && product.parent_Category != null" [src]="foodUrl + appId + '/products/' + product.parent_Category.id + '/' + product.id + '.jpg'" style="max-width: 150px"/>
      <div class="description-name">
        <h3>{{ product.name }}</h3>
        <h5 *ngIf="product.description != null && product.description != ''">{{ product.description }}</h5>
      </div>
    </div>  

    <div *ngIf="product.options.length == 0">
      <app-product [product]="product" [isMealDeal]="false" (productCompleted)="onProductCompleted($event)"></app-product>
    </div>


    <div *ngIf="product.options.length > 0">
      <div *ngFor="let option of product.options" class="option-block">
        <!-- Need to account for meal deals with options to select and meal deals with only 1 option -->
        <h3>{{ option.category_Name }}</h3>
        <select [(ngModel)]="option.selectedProduct" [name]="option.id" (ngModelChange)="onOptionChange(option.selectedProduct)">
          <option value="undefined">Please Select</option>
          <option *ngFor="let productOption of option.product_Option_Products" [ngValue]="productOption.product">
            <span *ngIf="!option.show_Surplus">{{ productOption.product.name }}</span>
            <span *ngIf="option.show_Surplus">{{ productOption.product.name }} - {{ productOption.product.cost | currency }}</span>
          </option>
        </select>

        <div *ngIf="option.selectedProduct != null">
          <app-product [option]="option" [product]="option.selectedProduct" [isMealDeal]="true" (productCost)="onProductCost($event)"></app-product>
        </div>
      </div>

      <app-product [product]="product" [isMealDeal]="true" (productCost)="onProductCost($event)"></app-product>
    </div>
  </div>
</div>


<button class="product-details-total" (click)="addMealDealToBasket()" *ngIf="isMealDeal">
  <div>Add To Basket - {{ cost | currency }}</div>
  <i class="far fa-arrow-alt-circle-right"></i>
</button>