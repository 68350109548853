import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common'; 

declare var RealexHpp;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  app = null;
  android_store: string = "";
  apple_store: string = "";

  // @ViewChild("btnTest") btnTest: ElementRef;

  constructor(private appService: AppService, private config: AppConfigService, private titleService: Title, private http: HttpClient, @Inject(DOCUMENT) document) {
    this.appService.getApp().then((app) => {
      this.app = app;
      if (app.id == 181) {
        this.titleService.setTitle(app.name + " | Menu Online");
      } else {
        this.titleService.setTitle(app.name + " | Takeaway Menu Online");
      }
    });

    this.android_store = config.data.android_store;
    this.apple_store = config.data.apple_store;
  }

  ngOnInit(): void {
    
  }

}
