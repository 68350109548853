import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable()
export class AppService {
  private app = null;

  constructor(private http: HttpClient, private config: AppConfigService) {}

  public getApp(force: boolean = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.app != null && !force) {
        resolve(this.app);
        return;
      }

      this.http.get(this.config.data.url + "api/app/get?id=" + this.config.data.app_id).subscribe(data => {
        this.app = data;
        resolve(data);
      }, error => {
        reject();
      })
    })
  }

  public isOpen(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.data.url + "api/app/get?id=" + this.config.data.app_id).subscribe((data: any) => {
        resolve(data.is_Open);
      }, error => {
        reject();
      })
    })
  }

  public isTempClosed(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.data.url + "api/app/get?id=" + this.config.data.app_id).subscribe((data: any) => {
        var obj = {
          isTempClosed: data.tempClosed,
          tempClosedMessage: data.tempClosedMessage
        }
        resolve(obj);
      }, error => {
        reject();
      })
    })
  }

  public getAddress(postcode: string) : Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.config.data.url + "api/address/get?postcode=" + postcode).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      });
    });
  }
}