<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="content">
  <div class="login-header">
    <i class="fas fa-user-shield"></i>
    <h3>Login</h3>
  </div>

  <form (ngSubmit)="onLogin()">
    <div class="form-group">
      <label>Email/Phone</label>
      <input type="text" class="form-control" [(ngModel)]="email" name="email"/>
    </div>

    <div class="form-group">
      <label>Password</label>
      <input type="password" class="form-control" [(ngModel)]="password" name="password"/>
    </div>

    <input type="submit" class="submit-btn" value="Login"/>

    <p style="font-weight: bold; color: red; text-align: center;">{{ errorMessage }}</p>
  </form>

  <a routerLink="/account/register">Click here to register</a>
</div>
