import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  app;
  currentDay = moment().weekday();
  gmapsUrl = "";

  constructor(private appService: AppService, private sanitizer: DomSanitizer) {
    appService.getApp().then((app) => {
      this.app = app;
      this.gmapsUrl = "https://maps.google.com/maps?width=100%&;height=600&;hl=en&q=" + this.app.address_Line_1 + "," + this.app.postcode + "&t=&z=15&ie=UTF8&iwloc=B&output=embed";
    });
  }

  ngOnInit(): void {
  }
}
