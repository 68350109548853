<div class="footer__block">
  <div>
    <a *ngIf="facebook" [href]="facebook" target="_blank"><i class="fab fa-facebook-square"></i></a>
    <a *ngIf="instagram" [href]="instagram" target="_blank"><i class="fab fa-instagram"></i></a>
    <a *ngIf="twitter" [href]="twitter" target="_blank"><i class="fab fa-twitter"></i></a>
  </div>
  <div class="seperator">
    |
  </div>
  <div class="powered-by__block">
    <a href="https://appcompanion.co.uk/" target="_blank">Powered By App Companion | V:2.0.4</a>
  </div>
</div>