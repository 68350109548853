import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() menuToggled = new EventEmitter<boolean>();

  isMenuOpen: boolean = false;
  isLoggedIn: boolean = false;

  app = null;
  constructor(private appService: AppService, private authService: AuthService, private router: Router) {
    this.appService.getApp().then((app) => {
      this.app = app;
    });

    this.authService.currentUser.subscribe((user) => {
      this.isLoggedIn = this.authService.userLoggedIn();
    })
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.userLoggedIn();
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuToggled.emit(this.isMenuOpen);
  }

  hideMenu() {
    this.isMenuOpen = false;
    this.menuToggled.emit(false);
  }

  login() {
    if (this.authService.userLoggedIn()) {
      this.authService.logout();
      return;
    }

    this.router.navigate(["/account/login"]);
  }
}
