import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BasketService } from 'src/app/services/basket.service';
import { AppService } from 'src/app/services/app.service';
import { HttpClient } from '@angular/common/http';
import { OrderService } from 'src/app/services/order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';

declare var Stripe;
const wait = (ms) => new Promise(res => setTimeout(res, ms));

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  objectKeys = Object.keys;
  objectValues = Object.values;

  @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('rmsForm') rmsFormElement : ElementRef;;
  
  isDelivery: boolean = null;
  paymentType: number = 1;

  costs;

  app;
  name: string = "";
  phone: string = "";
  addressLine1: string = "";
  addressLine2: string = "";
  postcode: string = "";

  addresses = [];
  postcodeSearch: string = "";
  selectedPostcode: string = "";

  notes: string = "";
  isLoading: boolean = false;

  stripe; // : stripe.Stripe;
  card;
  cardErrors;

  rmsValues = [];
  selectedStore = null;
  showStores: boolean = false;

  preOrder: any = null;
  promoCode: string = null;
  promotionApplied: boolean = false;

  constructor(private basketService: BasketService, private appService: AppService, private http: HttpClient, 
    private orderService: OrderService, private router: Router, private snotifyService: SnotifyService, 
    private authService: AuthService, private route: ActivatedRoute) {    
  }

  async ngOnInit() {
    this.promoCode = this.basketService.promoCode;
    this.promotionApplied = this.basketService.promoCode != null;
    this.app = await this.appService.getApp();
    if (this.app.settings.card_Only == true) {
      this.paymentType = 2;
    }


    this.basketService.costs.subscribe((costs) => {
      this.costs = costs;
    });

   
    this.authService.currentUser.subscribe(async (user) => {
      if (user == null) {
        this.name = "";
        this.phone = "";
        this.addressLine1 = "";
        this.addressLine2 = "";
        this.postcode = "";
      } else {
        this.name = user.name;
        this.phone = user.phone;
        this.addressLine1 = user.address_Line_1;
        this.addressLine2 = user.address_Line_2;
        this.postcode = user.postcode;
      }

      await this.basketService.checkExistingOrder();
      var preOrder = this.basketService.allowPreOrder.value;
      if (preOrder != null && preOrder.allow) {
        await this.setDelivery(preOrder.isDelivery);
        this.preOrder = preOrder;
      }

      await this.setDefaults();
      
    });
    this.route.queryParamMap.subscribe(async (params) => {
      if (params.get("id") != null) {
        await this.basketService.clearBasket();

        this.router.navigate(['confirmation']);
      }
    })
  }

  updateEtaTime(e) {
    this.preOrder.etaTime = e;
  }

  async setDefaults() {
    if (this.app.settings.card_Only == true) {
      setTimeout(() => {
        this.setPaymentType(2);
      }, 1000);

    }

    if (this.app.settings.order_Type != 0) {
      if (this.app.settings.order_Type == 1) 
        //this.isDelivery = true;
        await this.setDelivery(true);
      else
        // this.isDelivery = false;
        await this.setDelivery(false);
    }

  }

  async selectStore(store) {
    this.selectedStore = store;
    this.showStores = false;
    this.setDelivery(false, false);
    await this.initStripe(this.app, store);
  }

  async setDelivery(isDelivery, resetStore = true) {
    if (resetStore) {
      this.selectedStore = null;
      this.showStores = false;
    }

    if (this.app.additional_Stores != null && this.app.additional_Stores.length > 0 && !isDelivery && this.selectedStore == null) {
      this.showStores = true;
      return;
    }



    if (this.app.settings.order_Type > 0) {
      if (isDelivery && this.app.settings.order_Type == 2) {
        this.snotifyService.warning("We are not delivering at this time.");
        return;
      } else if (!isDelivery && this.app.settings.order_Type == 1) {
        this.snotifyService.warning("No collections available at this time.");
        return;
      }
    }


    if (isDelivery) {
      // Check that we are open at this time
      if (!this.app.is_Deliveries) {
        this.snotifyService.warning("We are not delivering at this time.");
        return;
      }

      if (!this.authService.userLoggedIn()) {
        this.snotifyService.warning("You must be logged in for delivery");
        return;
      }

      // Validate the minimum order
      if (this.basketService.costs.value.total < this.app.minimum_Order) {
        this.basketService.clearDelivery();
        this.snotifyService.warning("Minimum order for delivery is £" + this.app.minimum_Order.toFixed(2));
        return;
      }

      var selectedStoreId = null;
      if (this.app.id == 1) {
        selectedStoreId = await this.selectStoreFromPostcode();
        var store = {
          id: 0
        };
        if (selectedStoreId != 0) {
          store = this.app.additional_Stores.find(a => a.id == selectedStoreId);
        }
        await this.initStripe(this.app, store);
      }

      if (this.addressLine1 != null && this.addressLine1 != "" && this.postcode != "" && this.postcode != null) {
        this.isLoading = true;
        var deliveryCost = await this.basketService.calculateDeliveryCost(this.addressLine1, this.postcode, selectedStoreId, this.addressLine2);
        this.isLoading = false;
        if (deliveryCost == -1) {
          this.snotifyService.warning("Sorry we do not deliver to your address");
          // this.basketService.clearDelivery();
          // return;
        }
      }
    } else {
      this.basketService.clearDelivery();
      this.isDelivery = false;
      this.addressLine1 = "";
      this.addressLine2 = "";
      this.postcode = "";
      this.addresses = [];
      this.postcodeSearch = "";
      return;
    }
    
    this.isDelivery = isDelivery;
  }

  async setPaymentType(type) {
    if (type == 2 && this.app.stripe_Publishable_Key == null && this.app.paymentGatewayToken == null) {
      this.snotifyService.warning("Card payment not unavailable.");
      return;
    }

    this.paymentType = type;

    if (this.paymentType == 2) {
      // If we have RMS then we use RMS otherwise use Stripe
      if (this.app.paymentGatewayToken != null && this.app.paymentGatewayToken != "") {
        return;
      }

      await this.initStripe(this.app, null);
    }
  }

  async validateDelivery() {
    if (this.addressLine1 == null || this.addressLine1 == "") {
      this.snotifyService.warning("Enter address");
      return false;
    }

    if (this.postcode == null || this.postcode == "") {
      this.snotifyService.warning("Enter postcode");
      return false;
    }

    this.isLoading = true;
    var deliveryCost = await this.basketService.calculateDeliveryCost(this.addressLine1, this.postcode, null, this.addressLine2);
    this.isLoading = false;
    if (deliveryCost == -1) {
      this.snotifyService.warning("Sorry we do not delivery to your address");
      this.basketService.clearDelivery();
      return false;
    }

    return true;
  }
  
  applyPromo() {
    if (this.promoCode == null)
      return;

    this.promotionApplied = false;

    if (this.app.id == 35 || this.app.id == 38) {
      if (this.promoCode.toLowerCase() == "food10") {
        this.promotionApplied = true;
        this.basketService.setPromoCode(this.promoCode);

        alert("Successfully applied promotion");
      }
    }

    if ((this.app.id == 38 || this.app.id == 161) && this.promoCode.toLowerCase() == "luv14") {
      if (moment().format("DD/MM/YYYY") != '14/02/2021') {
        alert("Invalid promotion code");
        return;
      }

      this.promotionApplied = true;
      this.basketService.setPromoCode(this.promoCode);

      alert("Successfully applied promotion");
    }

    if (this.promotionApplied == false) {
      alert("Invalid promotion code");
    }
  }

  async placeOrder() {
    
    if (this.isDelivery == null) {
      this.snotifyService.warning("Select collection or delivery");
      return;
    }

    this.cardErrors = "";
    if (this.isDelivery) {
      var deliverySuccess = await this.validateDelivery();
      if (!deliverySuccess)
        return;
    }

    if (this.name == null || this.name == "") {
      this.snotifyService.warning("Enter name");
      return false;
    }

    if (this.phone == null || this.phone == "") {
      this.snotifyService.warning("Enter phone");
      return false;
    }

    var selectedStoreId = null;
    if (this.selectedStore != null)
      selectedStoreId = this.selectedStore.id;

    if (this.isDelivery && this.app.id == 1) {
      selectedStoreId = await this.selectStoreFromPostcode();
      var store = {
        id: 0
      };
      if (selectedStoreId != 0) {
        store = this.app.additional_Stores.find(a => a.id == selectedStoreId);
      }

      if (!this.storeOpenDeliveries(store)) {
        this.snotifyService.warning("We are not currently delivering. Please try again later.");
        return;
      }

      if (!this.storeOpen(store)) {
        this.snotifyService.warning("We are currently closed");
        return;
      }
    }



    this.isLoading = true;
    if (this.paymentType == 2) {
      var paymentIntent = "";

      var connectedAccountId = this.app.connectedAccountId;
      var additionalStoreId = null;
      if (this.selectedStore != null) {
        connectedAccountId = this.selectedStore.connectedAccountId;
        additionalStoreId = this.selectedStore.id;
      }

      if (connectedAccountId != null && connectedAccountId != '') {
        paymentIntent = await this.basketService.createStripeIntent(this.basketService.costs.value.total, additionalStoreId);
      } else {
        paymentIntent = await this.orderService.getStripePaymentIntent(this.app.app_Guid, this.costs.total, null);
      }

      var stripeResult = await this.stripe.handleCardPayment(paymentIntent, this.card);
      
      this.isLoading = false;

      if (stripeResult.error) {
        this.cardErrors = stripeResult.error.message;
        return;
      }

      if (stripeResult.paymentIntent.status == "succeeded" || stripeResult.paymentIntent.status == "requires_capture") {
        this.confirmOrder(stripeResult.paymentIntent.id, false, selectedStoreId);
      }
      return;
    } else {
      this.confirmOrder(null, false, selectedStoreId);
    }
  
  }

  getPromotionItem() {
    if (this.app.promotions_Cash == null || this.app.promotions_Cash.length == 0) return null;
    
    for (let promo of this.app.promotions_Cash.sort((a, b) => b.cost - a.cost)) {
      if (this.costs.total >= promo.cost) {
        return promo;
      }
    }

    return null;
  }

  async confirmOrder(stripeChargeId, isProcessing, selectedStoreId) : Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.isLoading = true;


      var cost = this.costs.total;
      if (this.app.connectedAccountId != null && this.app.connectedAccountId != '' && stripeChargeId != null && stripeChargeId != '') {
        cost -= 0.5;
      }


      var items = this.basketService.products.value;
      var promoItem = this.getPromotionItem();
      if (promoItem != null) {
        items.push({
          baseCost: 0,
          cost: 0,
          extras: [],
          name: promoItem.product.name,
          parent_Category_Id: promoItem.product.parent_Category_Id,
          prod_Id: promoItem.product.id,
          qty: 1
        });
      }


      var order = {
        app_Id: this.app.id,
        order_State: 0,
        is_Delivery: this.isDelivery,
        user_Id: null,
        cost: cost,
        delivery_Cost: this.costs.deliveryCost,
        cost_Before_Discount: this.costs.subTotal,
        notes: this.notes,
        name: this.name,
        phone: this.phone,
        address_Line_1: this.addressLine1,
        postcode: this.postcode,
        items: items,
        additional_Store_Id: selectedStoreId,
        isCard: false,
        stripe_Charge_Id: stripeChargeId,
        stripe_Payment_Intent_Id: stripeChargeId,
        isProcessing: isProcessing,
        paymentGatewaySuccess: false,
        connectedAccountId: this.app.connectedAccountId,
        orderLater: false,
        preOrderEta: null,
      };

      if (this.preOrder != null && this.preOrder.allow) {
        order.orderLater = true;
        order.preOrderEta = this.preOrder.etaTime;
      }

      if (this.promoCode != null && this.promoCode != "") {
        order.notes += " - " + this.promoCode;
      }

      this.orderService.placeOrder(order).then(async (dbOrder) => {
        if (isProcessing) {
          resolve(dbOrder);
          return dbOrder;
        }
  
        await this.basketService.clearBasket();
        
        this.isLoading = false;
        this.router.navigate(['confirmation']);
        resolve(true);
      }, (error) => {
        this.isLoading = false;
        if (error == null || error == "") {
          error = "Something went wrong. Please try again";
        }
        alert(error);
        this.cardErrors = error;
        reject();
      });
    })
    
  }

  formatPostcode(postcode) {
    var parts = postcode.replace(/\s+/g, '').toUpperCase().match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/);
    parts.shift();
    return parts.join(' ')
  }

  async selectStoreFromPostcode() {
    var postcodes = await this.http.get<any>("https://app.workflowdigital.com/postcodes/1.json").toPromise();
    var sortedPostcodes = postcodes.sort((a, b) => b.Postcode.length - a.Postcode.length);
    var deliveryPostcode = this.formatPostcode(this.postcode);

    var store = {
      id: 0,
      address_Line_1: this.app.address_Line_1,
      postcode: this.app.postcode,
      phone: this.app.phone
    };

    for (let postcode of sortedPostcodes) {
      if (deliveryPostcode.toLocaleLowerCase().indexOf(postcode.Postcode.toLowerCase()) > -1) {
        if (postcode.StoreId != null) {
          store = this.app.additional_Stores.find(a => a.id == postcode.StoreId);
        }

        this.app.selected_Store = store;
        break;
      }
    }
    
    return store.id;
  }

  searchAddresses() {
    if (this.postcodeSearch == undefined || this.postcodeSearch == "")
      return;

    this.isLoading = true;

    this.appService.getAddress(this.postcodeSearch).then((data) => {
      this.addresses = data.addresses;
      this.selectedPostcode = JSON.stringify(data.addresses[0]);

      this.isLoading = false;
    });
  }

  async selectAddress(value) {
    value = value.replace("\"", "");
    let data = value.toString().split(",");
    this.addressLine1 = data[0];
    this.addressLine2 = data[1];
    this.postcode = this.postcodeSearch.toUpperCase();
    this.postcodeSearch = "";

    this.addresses = [];


    this.isLoading = true;
    var deliveryCost = await this.basketService.calculateDeliveryCost(this.addressLine1, this.postcode, null, this.addressLine2);
    this.isLoading = false;
    
    if (deliveryCost == -1) {
      this.snotifyService.warning("Sorry we do not deliver to your address");
      this.basketService.clearDelivery();
    }
  }

  //#########################################################################################################
  //RMS
  //#########################################################################################################
 
  async submitRms() {
    if (this.isDelivery) {
      var deliverySuccess = await this.validateDelivery();
      if (!deliverySuccess)
        return;
    }

    this.isLoading = true;

    var dbOrder = await this.confirmOrder(null, true, null);
    await this.initRMS(dbOrder.order_Guid);

    await wait(100);

    this.rmsFormElement.nativeElement.submit();
  }

  async initRMS(id) {
    var key = "Offer37Fresh7Blame";

    var values = [
      { "merchantID": this.app.paymentGatewayToken },
      { "action": "SALE" },
      { "type": 1 },
      { "countryCode": 826 },
      { "currencyCode": 826 },
      { "amount": this.costs.total * 100 },
      { "cardNumber": '4012001037141112' },
      { "cardExpiryMonth": 12 },
      { "cardExpiryYear": 22 },
      { "cardCVV": '083' },
      { "customerName": 'Test Customer' },
      { "customerEmail": 'test@testcustomer.com' },
      { "customerPhone": '+44 (0) 123 45 67 890' },
      { "customerAddress": '16 Test Street' },
      { "customerPostcode": 'TE15 5ST' },
      { "orderRef": 'Test purchase' },
      { "transactionUnique": id },
      { "customerEmailMandatory": 0 },
      { "customerPhoneMandatory": 0 },
      //{ "redirectURL": 'http://81.110.8.110/app/rms?site=http://aead5d0cfa7a.ngrok.io/checkout' },
      { "redirectURL": 'http://81.110.8.110/app/rms?site=http://localhost:4200/checkout?id=' + id },
      { "callbackURL": "http://81.110.8.110/app/api/v2/rms/post" },
      { "signature": ""}
    ];

    values.pop();
    var signature = await this.createSignature(values, key);
    values.push({
      "signature": signature
    });

    this.rmsValues = values;
  }

  createSignature(values, key) {
    var vals = JSON.parse(JSON.stringify(values));
    vals = vals.sort(function(a, b) {
      var aKeys = Object.keys(a)[0];
      var bKeys = Object.keys(b)[0];
      
      if (aKeys < bKeys)
        return -1;

      if (aKeys > bKeys)
        return 1;

      return 0;
    });

    var ret = this.generateEncodedURL(vals).toString();

    return crypto.subtle.digest({ name: "SHA-512" }, new TextEncoder().encode(ret + key)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    });
  }

  generateEncodedURL(values) {
    var searchParameters = new URLSearchParams();
    for (let value of values) {
      var paramName = Object.keys(value)[0];
      var paramValue = value[paramName].toString();
      searchParameters.append(paramName, paramValue);
    }

    var ret = searchParameters.toString();
    ret = ret.replace('%0D%0A', '%0A');
    ret = ret.replace('%0A%0D', '%0A');
    ret = ret.replace('%0D', '%0A');

    return ret;
  }

  //#########################################################################################################
  //STRIPE
  //#########################################################################################################

  async initStripe(app, store) {
    this.isLoading = true;

    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
            color: '#aab7c4'
        }
      },
      invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
      }
    };

  
    // var paymentIntent = await this.basketService.createStripeIntent();
    var ac_publishable_key = 'pk_live_Z4Kx2Woevw99bUFfp0YVswgv';
    //var ac_publishable_key = 'pk_test_UtKgEnsc2c8iDOLrP0cykVhv';
    // console.log(app);
    var stripePublishableKey = app.stripe_Publishable_Key;
    var connectedAccountId = app.connectedAccountId;

    if (store != null && store.id > 0) {
      connectedAccountId = store.connectedAccountId;
      stripePublishableKey = store.stripe_Publishable_Key;
    }

    if (connectedAccountId != null && connectedAccountId != '') {
      this.stripe = Stripe(ac_publishable_key, { stripeAccount: connectedAccountId });
    } else {
      this.stripe = Stripe(stripePublishableKey);
    }

    const elements = this.stripe.elements();
    this.card = elements.create('card', { hidePostalCode: true,  style: style });
    this.card.mount(this.cardElement.nativeElement);
    this.card.addEventListener('change', ({ error }) =>   {
      this.cardErrors = error && error.message;
    });

    this.isLoading = false;
  }

  storeOpen(store) {
    // Get todays opening time
    var opening_Time = this.app.current_Opening_Time;
    var opens = moment(opening_Time.opens, "HH:mm");
    var closes = moment(opening_Time.closes, "HH:mm");

    if (store.id == 0 && opening_Time.isClosed) {
      store.is_Open = false;
      return false;
    }

    if (store.additional_Store_Opening != null && store.additional_Store_Opening.length > 0) {
      var day = store.additional_Store_Opening.find(a => a.day == moment().weekday());
      opens = moment(opening_Time.opens, "HH:mm").add(day.opening_Hours, 'hours');
      closes = moment(opening_Time.closes, "HH:mm").add(day.closing_Hours, 'hours');
    }

    var now = moment();

    if (now.hours() < 6 && closes.hours() < 6) {
      opens = opens.add(-1, 'days');
      closes = closes.add(-1, 'days');
    }

    if (now.isBetween(opens, closes)) {
      return true; 
    } else {
      return false;
    }
  }

  storeOpenDeliveries(store) {
    // Get todays opening time
    var opening_Time = this.app.current_Delivery_Time;
    var opens = moment(opening_Time.opens, "HH:mm");
    var closes = moment(opening_Time.closes, "HH:mm");

    if (store.id == 0 && opening_Time.isClosed) {
      store.is_Open = false;
      return false;
    }

    if (store.additional_Store_Opening != null && store.additional_Store_Opening.length > 0) {
      var day = store.additional_Store_Opening.find(a => a.day == moment().weekday());
      opens = moment(opening_Time.opens, "HH:mm").add(day.opening_Delivery_Hours, 'hours');
      closes = moment(opening_Time.closes, "HH:mm").add(day.closing_Delivery_Hours, 'hours');
    }

    var now = moment();

    if (now.hours() < 6 && closes.hours() < 6) {
      opens = opens.add(-1, 'days');
      closes = closes.add(-1, 'days');
    }

    if (now.isBetween(opens, closes)) {
      return true; 
    } else {
      return false;
    }
  }
}
