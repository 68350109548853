import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, DEFAULT_CURRENCY_CODE } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AppConfigService } from './services/app-config.service';
import { HttpClientModule } from '@angular/common/http';
import { AppService } from './services/app.service';
import { MenuComponent } from './pages/menu/menu.component';
import { ProductService } from './services/product.service';
import { ProductsComponent } from './pages/menu/products/products.component';
import { CategoriesComponent } from './pages/menu/categories/categories.component';
import { ProductDetailsComponent } from './pages/menu/product-details/product-details.component';
import { OpeningDialogComponent } from './components/dialogs/opening-dialog/opening-dialog.component';

import { PricePipe } from './pipes/price.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule } from '@angular/material/dialog'; 

import { CategoryService } from './services/category.service';
import { BasketComponent } from './components/basket/basket.component';
import { BasketService } from './services/basket.service';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { BasketSummaryComponent } from './components/basket/basket-summary/basket-summary.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { ToastrModule } from 'ngx-toastr';
import { ProductComponent } from './pages/menu/product-details/product/product.component';
import { FormsModule } from '@angular/forms';
import { OrderService } from './services/order.service';
import { ConfirmationComponent } from './pages/confirmation/confirmation.component';
import { AuthService } from './services/auth.service';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoginComponent } from './pages/account/login/login.component';
import { RegisterComponent } from './pages/account/register/register.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { OpeningTimePipe } from './pipes/opening-time.pipe';
import { SafePipe } from './pipes/safe-pipe';


import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EtaDialogComponent } from './components/dialogs/opening-dialog/eta-dialog/eta-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { TermsComponent } from './pages/terms/terms.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    MenuComponent,
    ProductsComponent,
    CategoriesComponent,
    BasketComponent,
    CheckoutComponent,
    BasketSummaryComponent,
    ProductDetailsComponent,
    LoadingSpinnerComponent,
    ProductComponent,
    ConfirmationComponent,
    SidebarComponent,
    LoginComponent,
    RegisterComponent,
    PrivacyComponent,
    ContactComponent,
    PricePipe,
    OpeningTimePipe,
    SafePipe,
    OpeningDialogComponent,
    EtaDialogComponent,
    FooterComponent,
    TermsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    SnotifyModule,
    DeviceDetectorModule,
    MatSidenavModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    AppService,
    ProductService,
    CategoryService,
    BasketService,
    OrderService,
    AuthService,
    AppConfigService, { provide: APP_INITIALIZER, useFactory: (config: AppConfigService) => () => config.load(), deps: [AppConfigService], multi: true },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'GBP'},
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
