import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AppConfigService {
  public data: AppConfig = null;

  constructor(private http: HttpClient) { }

  public load() {
    return new Promise((resolve, reject) => {
      return this.http.get("/assets/config.json").toPromise().then((data: any) => {
        this.data = new AppConfig();
        this.data = data;
        resolve(true);

      }).catch((error) => {
        reject(error);
      });
    });
  }
}

export class AppConfig {
  url: string;
  app_id: number;
  android_store: string;
  apple_store: string;
  allergy_info: string;
  food_url: string;
  includes_images: boolean;
  store_in_session: boolean;

  facebook: string;
  twitter: string;
  instagram: string;
}
