import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable()
export class ProductService {
  constructor(private http: HttpClient, private config: AppConfigService) {}

  public get(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.config.data.url + "api/product/get?id=" + id).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      })
    })
  }

  public getAll(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(this.config.data.url + "api/product/getall?app_id=" + this.config.data.app_id + "&show_Hidden=" + false).subscribe(data => {
        resolve(data);
      }, error => {
        reject();
      })
    })
  }
}