import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { AppService } from './app.service';
import { MAT_HAMMER_OPTIONS } from '@angular/material/core';
import { SnotifyService } from 'ng-snotify';
import { config, env } from 'process';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

@Injectable()
export class BasketService {
  private _products = [];
  products: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  costs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  deliveryCost: number = 0;
  app;

  //allowPreOrder: boolean = false;
  allowPreOrder: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);

  promoCode: string;

  constructor(private appService: AppService, private http: HttpClient, private config: AppConfigService, private snotifyService: SnotifyService) {
    appService.getApp().then((app) => {
      this.app = app;
      this.checkExistingOrder();
    });
  }

  private getKey() {
    var key = localStorage.getItem("key");
    if (key == null || key == undefined) {
      key = uuidv4();
      localStorage.setItem("key", key);
    };

    return key;
  }

  private async storeInSession(json) {
    var key = this.getKey();

    var dto = {
      key: key,
      orderJson: json
    }

    await this.http.post(this.config.data.url + "api/v2/order/addtosession", dto).toPromise();
    //await this.getFromSession();
  }

  private async getFromSession() : Promise<[]> {
    var key = this.getKey();
    return new Promise((resolve, reject) => {
      this.http.get(this.config.data.url + "api/v2/order/getfromsession?key=" + key).subscribe((data: any) => {
        resolve(data);
      }, error => {
        reject();
      })
    })
  }

  public setPromoCode(code: string) {
    this.promoCode = code;
    this.calculateCosts();
  }

  public updatePreOrder(allowPreOrder: boolean, etaTime: string, isDelivery: boolean) {
    var dto = {
      allow: allowPreOrder,
      etaTime: etaTime,
      isDelivery: isDelivery
    };
    this.allowPreOrder.next(dto);
    localStorage.setItem("AllowPreOrder", JSON.stringify(dto));
  }
  

  public clearDelivery() {
    this.deliveryCost = 0;
    this.calculateCosts();
  }

  public async clearBasket() {
    localStorage.setItem("AllowPreOrder", null);
    if (this.config.data.store_in_session)
      await this.storeInSession("[]");
    else
      localStorage.setItem("CurrentItems", null);
    
    this._products = [];
    
    this.products.next(this._products);
    this.calculateCosts();

    if (!this.config.data.store_in_session)
      localStorage.setItem("CurrentItems", JSON.stringify(this._products));
  }

  public async addQty(product) {
    var idx = this._products.indexOf(product);
    var prod = this._products[idx];
    prod.qty++;
    prod.cost = prod.baseCost * prod.qty;

    this.products.next(this._products);
    this.calculateCosts();

    if (this.config.data.store_in_session)
      await this.storeInSession(JSON.stringify(this._products));
    else
      localStorage.setItem("CurrentItems", JSON.stringify(this._products));
  }

  public async removeQty(product) {
    var idx = this._products.indexOf(product);
    var prod = this._products[idx];
    prod.qty--;
    prod.cost = prod.baseCost * prod.qty;

    if (prod.qty == 0) {
      var idx = this._products.indexOf(prod);
      if (idx > -1) {
        this._products.splice(idx, 1);
      }
    }

    this.products.next(this._products);
    this.calculateCosts();

    //localStorage.setItem("CurrentItems", JSON.stringify(this._products));
    if (this.config.data.store_in_session)
      await this.storeInSession(JSON.stringify(this._products));
    else
      localStorage.setItem("CurrentItems", JSON.stringify(this._products));
  }

  public async addToBasket(product) {
    if (this._products == null)
      this._products = [];

    var extras = product.selected_extras == null ? [] : product.selected_extras;
    var size = product.selected_size;
    var additional_size = product.additional_size;
    var options = product.selected_options == null ? [] : product.selected_options;

    var order_item = {
      cost: parseFloat(product.cost.toFixed(2)),
      baseCost: parseFloat(product.cost.toFixed(2)),
      name: product.name,
      qty: 1,
      extras: extras,
      options: [],
      prod_Id: product.id,
      parent_Category_Id: product.parent_Category_Id
    }

    order_item = this.processItem(order_item, size, additional_size, options);

    var newProd = _.clone(order_item);

    for (let product of this._products) {
      if (_.isEqual(newProd.name, product.name) && _.isEqual(newProd.extras, product.extras) && _.isEqual(newProd.options, product.options)) {
        product.qty++;
        product.cost = product.baseCost * product.qty;
        
        this.products.next(this._products);
        this.calculateCosts();
        return;
      }
    }

    this._products.unshift(order_item);
    this.products.next(this._products);
    this.calculateCosts();

    if (this.config.data.store_in_session)
      await this.storeInSession(JSON.stringify(this._products));
    else
      localStorage.setItem("CurrentItems", JSON.stringify(this._products));
  }

  public async updateProducts(products) {
    this._products = products;
    this.products.next(products);

    if (this.config.data.store_in_session)
      await this.storeInSession(JSON.stringify(this._products));
    else
      localStorage.setItem("CurrentItems", JSON.stringify(this._products));
  }

  public async checkExistingOrder() {
    // Check to see if we have an order in the local storage
    var currentOrder = null;
    if (this.config.data.store_in_session)
      currentOrder = await this.getFromSession();
    else
      currentOrder = localStorage.getItem("CurrentItems");

    if (currentOrder != null) {
      if (!this.config.data.store_in_session)
        this._products = JSON.parse(currentOrder);
      else
        this._products = currentOrder;

      this.products.next(this._products);
      this.calculateCosts();
    }

    // If we are currently open we can clear the pre order anyway
    var isOpen = await this.appService.isOpen();
    if (isOpen) {
      this.updatePreOrder(false, null, false);
    } else {
      var preOrderJson = localStorage.getItem("AllowPreOrder");
      
      if (preOrderJson != null && preOrderJson != "null" && preOrderJson != "") {
        var preOrder = JSON.parse(preOrderJson);
        this.updatePreOrder(preOrder.allow, preOrder.etaTime, preOrder.isDelivery);
      } else {
        this.updatePreOrder(false, null, false);
      }
    }    
  }

  createStripeIntent(amount: number, additionalStoreId: number) : Promise<string> {
    return new Promise(async (resolve, reject) => {
      var app = await this.appService.getApp(true);
      var url = this.config.data.url + "api/v2/stripe/createpaymentintent?appGuid=" + app.app_Guid + "&cost=" + amount;
      if (additionalStoreId != null) {
        url += "&additionalStoreId=" + additionalStoreId;
      }
      this.http.get(url).subscribe((token: string) => {
        resolve(token);
        return;
      });
    });
  }
  

  public calculateProductCost(product, size, additional_size, extras, addCost, addExtraSurplus) {
    // Need to deep clone these projects
    product = JSON.parse(JSON.stringify(product));
    size = JSON.parse(JSON.stringify(size));
    additional_size = JSON.parse(JSON.stringify(additional_size));
    extras = JSON.parse(JSON.stringify(extras));

    var cost = product.cost;

    if (!addCost) {
      cost = 0;
    }

    var extraSurplus = 0;

    if (size != null) {
      extraSurplus = size.extra_Surplus;
      cost = size.surplus;
      if (additional_size != null) {
        cost += additional_size.surplus;
      }
    }


    if (addExtraSurplus) {
      for (let extraCategory of extras) {
        // Break down all the extras into individual extras for pricing
        var selectedExtras = [];
        for (let extra of extraCategory.extras.filter(a => a.selected)) {
          // Deep clone the extra
          for (var i = 0; i < extra.qty; i++) 
            selectedExtras.push(JSON.parse(JSON.stringify(extra)));
        }

        var noOfFree = extraCategory.no_Of_Free;
        
        if (noOfFree > 0) {
          for (let extra of selectedExtras) {
            extra.isFree = false;
          }

          var cheapestExtras = selectedExtras.filter(a => a.cost != 0 && !a.free).reverse().sort((a, b) => (a.cost > b.cost) ? 1 : -1).slice(0, noOfFree);
          for (let extra of cheapestExtras) {
            extra.isFree = true;
          }
        }

        for (let extra of selectedExtras) {
          if (!extra.selected || extra.free || extra.isFree) 
            continue;

          var extraCost = extra.cost == 0 ? 0 : (extra.cost + extraSurplus);
          cost += extraCost;
        }
      }
    }
    return cost;
  }

  private calculateCosts() {
    var costs = this.calculateCost(this._products, 0);
    var _costs = {
      subTotal: costs[0],
      discount: costs[1],
      total: costs[2] + this.deliveryCost,
      deliveryCost: this.deliveryCost
    }
    
    if (this.app.connectedAccountId != null && this.app.connectedAccountId != '') {
      _costs.total += 0.5;
    }

    this.costs.next(_costs);
  }

  private calculateCost(products, deliveryCost) {
    // Reset prices
    for (let item of products) {
      if (item.exPromoPrice != null) {
        item.cost = item.exPromoPrice;
      }
    }

    if (this.promoCode != null && this.app.id == 161) {
      //var pizzaId = 633;
      var pizzaId = 1599;
      var pizzaItems = products.filter(i => i.parent_Category_Id == pizzaId).sort((a, b) => a.cost - b.cost);
      for (let pizza of pizzaItems) {
        if (pizza.exPromoPrice != null) {
          pizza.cost = pizza.exPromoPrice;
        }
  
        pizza.exPromoPrice = pizza.cost;
        pizza.isPromoItem = false;
      }

      // Set the cheapest pizza to free
      if (pizzaItems.length > 1) {
        pizzaItems[0].isPromoItem = true;
        pizzaItems[0].cost = 0;
      }
    }


    // Get the app discount
    var discountPercent = this.app.app_Discount;
    var applyDiscountIfOver = this.app.apply_Discount_If_Over;

    if (this.promoCode != null && (this.app.id == 35 || this.app.id == 38)) {
      discountPercent = 10;
      applyDiscountIfOver = 0;
    }

    var subTotal = 0;
    if (products != null) {
      for (let product of products) {
        if (!product.isPromoItem)
          subTotal += parseFloat(product.cost);
      }
    }

    var discount = 0;
    if (subTotal >= applyDiscountIfOver) {
      discount = (subTotal / 100) * discountPercent;
    }
      
    var total = (subTotal + deliveryCost) - discount;

    return [subTotal, discount, total];
  }

  private processItem(item, selected_size, selected_additional_size, selected_options) : any {
    //var name = item.name;
    //var cost = item.cost;

    var extraSurplus = 0;
    if (selected_size != null) {
      var size = selected_size.size;
      //cost = selected_size.surplus;
      extraSurplus = selected_size.extra_Surplus;
      if (selected_additional_size != null) {
        size += " - " + selected_additional_size.option;
        //cost += selected_additional_size.surplus;
      }

      item.name = size + " - " + item.name;
      //item.cost = cost;
    }

    // Add the extra costs
    for (let extraCategory of item.extras) {
      for (let extra of extraCategory.extras) {
        extra.cost = (extra.cost == 0 || extra.free || extra.isFree) ? 0 : (extra.cost + extraSurplus);
        //item.cost += extra.cost;
      }
    }

    // Iterate our options
    item.options = [];
    var options = [];

    for (let option of selected_options) {  
      var optionExtras = option.selectedProduct.selected_extras == null ? [] : option.selectedProduct.selected_extras;
      var newOption = {
        name: option.selectedProduct.name,
        extras: optionExtras,
      }

      if (option.show_Surplus) { 
        item.cost += option.selectedProduct.cost
      }

      // Iterate our cost 
      for (let extraCategory of newOption.extras) {
        for (let extra of extraCategory.extras) {
          if (option.show_Extra_Surplus)
            item.cost += (extra.cost + extraSurplus);
        }
      }

      options.push(newOption);
    }

    item.options = options;
    item.cost = parseFloat(item.cost.toFixed(2));
    //item.baseCost = item.cost;

    return item;
  }
  
  calculateDeliveryCost(addressLine1: string, postcode: string, additionalStoreId: number, addressLine2): Promise<number> {
    return new Promise(async (resolve, reject) => {
      if (addressLine2 == undefined)
        addressLine2 = "";

      this.app = await this.appService.getApp(true);

      var url = this.config.data.url + "api/address/getdistance?address_line_1=" + addressLine1 + "&postcode=" + postcode + "&app_id=" + this.config.data.app_id + "&address_line_2=" + addressLine2;
      if (additionalStoreId != null) {
        url += "&additional_store_id=" + additionalStoreId;
      }

			this.http.get(url).subscribe((miles: number) => {
        if (miles == -1) {
          resolve(-1);
          return;
        }

				if (this.app.maximum_Delivery > 0) {
					if (miles > this.app.maximum_Delivery) {
            resolve(-1);
            return;
					}
        }

        var deliveryMiles = this.app.delivery_Per_Miles.sort((a, b) => a.start_Mile - b.end_Mile);

				// if delivery type is 0 then it is a FLAT FEE.
				if (this.app.delivery_Type == 0) {
					this.deliveryCost = this.app.flat_Delivery_Cost;
				} else if (this.app.delivery_Type == 1) {
					// Loop through the delivery per miles to see which 
					// step this miles falls into
					this.deliveryCost = 0;
					if (miles < 1) {
						// If the miles is less than 1 then we do not need to iterate over our delivery per miles
						// We simply need to get the first one and caluclate accordingly
						this.deliveryCost += deliveryMiles[0].cost;
					} else {
						for (let perMile of deliveryMiles) {
							if (miles >= perMile.start_Mile && miles <= perMile.end_Mile) {
								this.deliveryCost = perMile.cost;
								break;
							}
						}
					}
				}


				if (this.deliveryCost < 0) { this.deliveryCost = 0; }

				this.calculateCosts();
				resolve(this.deliveryCost);
      });
    });
  }
}