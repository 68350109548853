<app-navbar></app-navbar>

<!-- <mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav mode="side" class="sidenav-wrapper" [fixedInViewport]="true">
    <div class="category-drawer-btn">
      <i class="far fa-times-circle" (click)="sidenav.toggle()"></i>
    </div>
    <app-categories (selected)="sidenav.toggle(); onSelected($event);"></app-categories>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="top-bar-content">
      <i *ngIf="!showBackButton" class="fas fa-bars" (click)="sidenav.toggle()"></i>
      <i *ngIf="showBackButton" class="fas fa-arrow-left" (click)="goBack()"></i>
      <app-basket class="basket"></app-basket>
    </div>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->

<div class="top-bar-content">
  <app-basket class="basket"></app-basket>
</div>

<router-outlet></router-outlet>