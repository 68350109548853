import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable()
export class AuthService {
  currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private router: Router, private config: AppConfigService) { 
    var existingUser = localStorage.getItem("user");
    if (existingUser != "" && existingUser != null) {
      this.currentUser.next(JSON.parse(existingUser));
    }
  }

  public userLoggedIn() {
    var existingUser = localStorage.getItem("user");
    if (existingUser == "" || existingUser == null)
      return false;

    // Check if this cookie si the correct app id
    // check this against the service to see if we are logged in
    var user = JSON.parse(localStorage.getItem("user"));
    if (user == null || user == "")
      return false;
      
    if (user.app_Id != this.config.data.app_id)
      return false;

    return true;
  }

  public isAdmin(): boolean {
    if (this.userLoggedIn()) {
      var user = JSON.parse(localStorage.getItem("user"));
      return user.isAdmin;
    } else {
      return false;
    }
  }

  public setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
    this.currentUser.next(user);
  }

  public getUser(): BehaviorSubject<any> {
    if (this.currentUser.value != null) {
      this.currentUser.next(this.currentUser.value);
    }

    var existingUser = localStorage.getItem("user");
    if (existingUser == "" || existingUser == null)
      return null;

    var user = JSON.parse(existingUser);
    if (user.app_Id != this.config.data.app_id)
      return null;
    
    this.currentUser.next(JSON.parse(user));
  }

  public logout() {
    this.currentUser.next(null);
    localStorage.setItem("user", null);
  }
}
