<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<app-navbar></app-navbar>

<div class="checkout-block" *ngIf="app != null">
  <div class="inner-checkout-block">
    <h1><i class="fa fa-lock" aria-hidden="true"></i>SECURE CHECKOUT</h1>

    <div class="options-block">
      <p><i class="fas fa-truck"></i>Delivery Method</p>
      <div class="option-methods-block">
        <div class="option-block" [ngClass]="{'selected': isDelivery == false}" (click)="setDelivery(false)">
          <div class="option">
            <div class="option-text">COLLECTION</div>
          </div>
        </div>
        <div class="option-block" [ngClass]="{'selected': isDelivery}" (click)="setDelivery(true)">
          <div class="option">
            <div class="option-text">DELIVERY</div>
          </div>
        </div>
      </div>
    </div>

    <div class="options-block" *ngIf="!app.settings.card_Only">
      <p><i class="fas fa-money-bill"></i> Payment Method</p>
      <div class="option-methods-block">
        <div class="option-block" [ngClass]="{'selected': paymentType == 1}" (click)="setPaymentType(1)">
          <div class="option">
            <div class="option-text">CASH</div>
          </div>
        </div>
        <div class="option-block" [ngClass]="{'selected': paymentType == 2}" (click)="setPaymentType(2)">
          <div class="option">
            <div class="option-text">CARD</div>
          </div>
        </div>
      </div>
    </div>

    <form *ngIf="costs">
      <div class="form-group">
        <label>Enter Name</label>
        <input type="text" [(ngModel)]="name" name="name" />
      </div>

      <div class="form-group">
        <label>Enter Phone #</label>
        <input type="text" [(ngModel)]="phone" name="phone" />
      </div>

      <div *ngIf="isDelivery && addresses.length == 0">
        <div class="postcode-lookup">
          <div class="form-group">
            <label>Postcode Lookup</label>
            <div class="form-group-flex">
              <input type="text" class="form-control" [(ngModel)]="postcodeSearch" name="postcodeSearch"/>
              <input type="button" value="Search" (click)="searchAddresses()"/>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Address Line 1</label>
          <input type="text" [(ngModel)]="addressLine1" name="addressLine1" />
        </div>

        <div class="form-group">
          <label>Postcode</label>
          <input type="text" [(ngModel)]="postcode" name="postcode" />
        </div>
      </div>

      <div class="address-block" *ngIf="addresses.length > 0">
        <ul>
          <li *ngFor="let address of addresses"><a (click)="selectAddress(address)">{{ address }}</a></li>
        </ul>
      </div>

      <div class="form-group">
        <label>Additional Notes</label>
        <textarea rows="4" [(ngModel)]="notes" name="notes"></textarea>
      </div>

      <div class="form-group" *ngIf="!promotionApplied">
        <label>Promotional Code</label>
        <div class="promo-group">
          <input type="text" [(ngModel)]="promoCode" name="promoCode" />
          <button class="promo-btn" (click)="applyPromo()">APPLY</button>
        </div>
      </div>

      <div *ngIf="promotionApplied && app.id == 161">
        <p style="padding: 10px; background: #1abc44; border: 1px solid green; color: #FFF; text-align: center;">Promotion applied: Buy 1 Pizza Get 1 Pizza Free</p>
      </div>

      <div *ngIf="promotionApplied && (app.id == 38 || app.id == 35)">
        <p style="padding: 10px; background: #1abc44; border: 1px solid green; color: #FFF; text-align: center;">Promotion applied: 10% off</p>
      </div>

      <div *ngIf="preOrder != null" style="margin-bottom: 15px;">
        <p style="margin: 0px; padding: 0px;">Pre-order time:</p>
        <app-eta-dialog [isDelivery]="isDelivery" [etaTime]="preOrder.etaTime" (etaSet)="updateEtaTime($event)"></app-eta-dialog>
      </div>

      <div class="stripe-elements" [hidden]="paymentType != 2 || app.paymentGatewayToken != null">
        <div #cardElement>
          <!-- A Stripe Element will be inserted here. -->
        </div>
      </div>
      <p style="color: red; font-size: 18px; margin-top: 10px;" *ngIf="cardErrors">{{ cardErrors }}</p>

      <div class="totals-block">
        <div class="basket-totals">
          <div class="total-block" *ngIf="app.connectedAccountId == null || app.connectedAccountId == ''">
            <div class="total-name">Subtotal:</div>
            <div class="total-cost"> {{ costs.subTotal | currency: 'GBP' }}</div>
          </div>

          <div class="total-block" *ngIf="app.connectedAccountId == null || app.connectedAccountId == ''">
            <div class="total-name">Discount:</div>
            <div class="total-cost"> {{ costs.discount | currency: 'GBP' }}</div>
          </div>

          <div class="total-block" v-if="deliveryCost > 0">
            <div class="total-name">Delivery:</div>
            <div class="total-cost"> {{ costs.deliveryCost | currency: 'GBP' }}</div>
          </div>

          <div class="total-block" *ngIf="app.connectedAccountId != null && app.connectedAccountId != ''">
            <div class="total-name">Service Fee:</div>
            <div class="total-cost">£0.50</div>
          </div>

          <div class="total-block">
            <div class="total-name">Total:</div>
            <div class="total-cost total"> {{ costs.total | currency: 'GBP' }}</div>
          </div>
        </div>
      </div>
      
      <div [hidden]="app.paymentGatewayToken == null || paymentType != 2">
        <form #rmsForm ngNoForm action="https://gateway.retailmerchantservices.co.uk/paymentform/" method="post" style="margin: 0;">
          <input type="hidden" *ngFor="let value of rmsValues" [name]="objectKeys(value)" [value]="objectValues(value) ">
          <input type="button" class="btn-checkout" value="Place Order ({{ costs.total | currency }})" (click)="submitRms()" />
        </form>
      </div>

      <a type="button" class="btn-checkout"
        (click)="placeOrder()" *ngIf="app.paymentGatewayToken == null || paymentType != 2">Place Order ({{ costs.total | currency }})</a>
    </form>
  </div>

  <app-basket-summary class="basket" [showTotals]="false"></app-basket-summary>
</div>