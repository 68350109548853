import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string = "";
  password: string = "";
  errorMessage: string = "";

  isLoading: boolean = false;

  constructor(private http: HttpClient, private config: AppConfigService, private authService: AuthService, private router: Router, private snotifyService: SnotifyService) { }

  ngOnInit(): void {
  }

  validate(): boolean { 
    if (this.email == "" || this.email == null) {
      return false;
    }

    if (this.password == "" || this.password == null) {
      return false;
    }

    return true;
  }

  onLogin() {
    if (!this.validate()) {
      this.snotifyService.warning("Enter email & password");
      return;
    }

    this.isLoading = true;

    var dto = {
      email: this.email,
      password: this.password,
      app_Id: this.config.data.app_id
    };

    this.http.post(this.config.data.url + "api/account/login", dto).subscribe(data => {
      this.authService.setUser(data);
      this.router.navigate(['/menu']);

      this.isLoading = false;
    }, error => {
      this.snotifyService.error("Invalid email or password");
      //this.errorMessage = "Invalid email or password";
      this.isLoading = false;
    })  
  }
}
