<div class="content" (click)="isSummaryOpen = !isSummaryOpen">
  <span *ngIf="allowPreOrder" style="padding-right: 10px;">
    ETA: {{ etaTime }}
  </span>

  <i class="fas fa-shopping-basket"></i>
  ({{ qty }})
  <span *ngIf="subTotal">{{ subTotal | currency: 'GBP' }}</span>
</div>

<div class="basket-window" *ngIf="isSummaryOpen">
  <div class="close-btn">
    <i class="far fa-times-circle" (click)="isSummaryOpen = false"></i>
  </div>

  <app-basket-summary (onCancel)="isSummaryOpen = false"></app-basket-summary>
</div>