import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import * as moment from 'moment';
import { Router, NavigationStart } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Output() navSelected = new EventEmitter<boolean>();
  
  order;
  showCategories: boolean = false;

  constructor(private orderService: OrderService, private router: Router) { 
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.indexOf("menu") > -1) {
          this.showCategories = true;
        } else {
          this.showCategories = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.updateOrder();
  }

  selectedNav() {
    this.navSelected.emit();
  }

  updateOrder() {
    var order = this.orderService.getLocalOrder();
    if (order != null) {
      var diffDays = moment().diff(moment(order.order_Placed_At), 'days');
      if (diffDays < 1) {
        this.order = order;
      } else {
        this.order = null;
      }
    }
  }

  onSelected(id) {
    setTimeout(() => {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      //const y = el.getBoundingClientRect().top;
      //const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
      //el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      //window.scrollBy(0, -100);
      //window.scrollTo({ top: y, behavior: 'smooth'});
    }, 100);
  }

  findAncestor (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}
}
